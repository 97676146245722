export const checkValidPassword = (password) => {
        return password.length >= 8 && password.length <= 64;
    }

export const checkValidEmail = (emailText) => {
    const re = /^\S+@\S+\.\S+$/;
    return emailText.match(re);
}

export const minimumPasswordLength = 8;
export const maximumPasswordLength = 64;