import '../App.css';

import React, { useEffect, useState, useRef } from 'react';
import {Table, TableHead, TableBody, TableContainer, TableCell, TableRow, Typography, Button} from "@mui/material";
import {color_palette, font_palette} from '../style/StylePalette.js';
import {globalData} from '../Global.js';
import QueryInput from '../components/QueryInput.js';

import {format, parseISO} from 'date-fns';

import axios from 'axios';

import { CsvBuilder } from 'filefy';

function  SummaryTable  (props) {
    const buttonStyleHighlight = {
        fontFamily: font_palette["text"],
        color: color_palette["black"],
        border: "1px solid " + color_palette["primary"],
        fontSize: 12,
        width: 300,
    }

    var res = (<></>);

    // column names of numeric values that are not a percentage
    var nonPtg = [];
    // column names of numeric values that are a percentage with one ptg after
    var morePtg = [];
    // rows with one more ptg poitn
    var morePtgRows = [];

    var precision = 0;

    if (typeof props.precision !== 'undefined') {
        precision = props.precision;
    }

    if (typeof props.nonPtg !== "undefined") {
        nonPtg = props.nonPtg;
    }

    if (typeof props.morePtg !== "undefined") {
        morePtg = props.morePtg;
    }

    if (typeof props.morePtgRows !== "undefined") {
        morePtgRows = props.morePtgRows;
    }


  const processData = (data_entry, isPtg, morePtg) => {
    if (typeof data_entry == "object") {
        if (data_entry instanceof Date) {
            return format(data_entry, "dd-MMM-yy");
        } else {
            return JSON.stringify(data_entry);
        }
    } else if (typeof data_entry =="number") {
        if (isPtg) {
            if (morePtg) {
                return String((data_entry*100).toFixed(precision+1)) + "%"
            } else {
                return String((data_entry * 100).toFixed(precision)) + "%";
            }
        }
        if (data_entry == Math.round(data_entry)) {
            return (String(data_entry));
        }
        return String(data_entry.toFixed(3));
    } else if (typeof data_entry == "string") {
        if (parseISO(data_entry) != "Invalid Date") {
            return format(parseISO(data_entry), "dd-MMM-yyyy");
        } else if (data_entry.startsWith("2020")) {
            console.log(`failed to detect date: ${data_entry}`)
        }
        return data_entry;
    }
    return String(data_entry);
  }

  const exportData = () => {
      if (props.direction == "colsfirst") {
        var csvBuilder = new CsvBuilder('summary_table.csv').setColumns(props.header);

        var data_length = props.data[props.header[0]].length;

        for (var i = 0; i < data_length; i++) {
            csvBuilder.addRow(props.header.map((k) => props.data[k][i]));
        }

        csvBuilder.exportFile();
      } else if (props.direction == "cross") {
        var csvBuilder = new CsvBuilder('summary_table.csv').setColumns(props.header);

        for (var i = 0; i < props.data.length; i++) {
            csvBuilder.addRow(props.data[i]);
        }

        csvBuilder.exportFile();
      }
  }

  if (props.direction == "colsfirst") {
    res = (<>
          <TableContainer sx={{maxHeight: 500}}>
          <Table>
            <TableHead sx={{maxHeight: 20}}>
               <TableRow sx={{maxHeight: 20, backgroundColor: color_palette["black"], color: color_palette["white"]}}>
                    {props.header.map((k)=>{return (

                        <TableCell><Typography sx={{color: color_palette["white"]}}>{k}</Typography></TableCell>
                    )})}
               </TableRow>
            </TableHead>
            <TableBody sx={{maxHeight:"100%"}}>
               {props.data[props.header[0]].map((x,i)=>{return (<TableRow>
                        {props.header.map((k)=>{return (
                            <TableCell>{processData(props.data[k][i], props.ptg && !nonPtg.includes(k), props.ptg && (morePtg.includes(k) || morePtgRows.includes(i)))}</TableCell>
                        )})}
                    </TableRow>);
               })}
            </TableBody>
           </Table>
           </TableContainer>
           <br />
          <Button sx={buttonStyleHighlight} onClick={exportData}>Export Table to csv</Button>
          <br />
           </>);
  } else if (props.direction == "cross") {
    res = (<>
          <TableContainer sx={{maxHeight: 500}}>
          <Table>
            <TableHead>
               <TableRow>
                    {props.header.map((k)=>{return (
                        <TableCell sx={{backgroundColor: color_palette["primary"]}}>
                            <Typography sx={{color: color_palette["white"]}}>{k}</Typography>
                        </TableCell>
                    )})}
               </TableRow>
            </TableHead>
            <TableBody>
               {props.data.map((row,i)=>{return (<TableRow>
                        {props.header.map((k,j)=>{return (
                            <TableCell>{processData(props.data[i][j], props.ptg && !nonPtg.includes(k), props.ptg && morePtg.includes(k))}</TableCell>
                        )})}
                    </TableRow>);
               })}
            </TableBody>
           </Table>
           </TableContainer>
           <br />
          <Button sx={buttonStyleHighlight} onClick={exportData}>Export Table to csv</Button>
          <br />
           </>);
  }
  return res;
}

export default SummaryTable;