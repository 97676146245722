import '../App.css';

import TimeSeriesChart from '../components/TimeSeriesChart.js';
import SummaryTable from '../components/SummaryTable.js';
import DriftChart from '../components/DriftChart.js';
import React, { useEffect, useState } from 'react';
import {Card, Typography, Button, Divider, Grid} from "@mui/material";

import {color_palette, font_palette} from '../style/StylePalette.js';

import QueryResults from '../components/QueryResults.js';

import {globalData} from '../Global.js';

import axios from 'axios';

function  QueryResultPage  (props) {

    const headerStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["black"],
        fontSize: 36,
        fontWeight: "bold",
        textAlign: "left"
    }

    const mainHeaderStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["white"],
        fontSize: 36,
        fontWeight: "bold",
        textAlign: "left"
    }
    const descStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["white"],
        fontSize: 16,
        textAlign: "left"
    }



    const topStyle = {
        backgroundColor: color_palette["black"],
        textAlign: "left",
    }



    const [ts_data, setTsData] = React.useState({
        datasets: [{
            label: "no data",
            data: []
        }]
    });

    const [histPnlData, setHistPnlData] = React.useState({
        datasets: [{
            label: "no data",
            data: []
        }]
    });

    const [returnData, setReturnData] = React.useState({
        datasets: [{
            label: "no data",
            data: []
        }]
    });


    const vizColors = ["#dc3545", "#0d6efd", "#0dcaf0"];

    const [queryStatus, setQueryStatus] = React.useState("waiting");
    const [refHook, setRefHook] = React.useState(0);
    const [summaryData, setSummaryData] = React.useState({});
    const [summaryHeader, setSummaryHeader] = React.useState([]);
    const [returnDataTabular, setReturnDataTabular] = React.useState({});
    const [returnDataHeader, setReturnDataHeader] = React.useState([]);

    const updateReturnData = (return_json) => {
        /* use summary data (in json format) to populate historical pnl chart */
        const NORMAL_RETURN_IDX = 0;
        const POSTEVENT_RETURN_IDX = 1;
        var all_datasets = [];
        all_datasets[NORMAL_RETURN_IDX] = {
                    label: `normal drift`,
                    backgroundColor: vizColors[NORMAL_RETURN_IDX % vizColors.length],
                    borderColor: vizColors[NORMAL_RETURN_IDX % vizColors.length],
                    borderWidth: 2,
                    pointRadius: 0,
                    fill: true,
                    spanGaps: true,
                    showLine: true,
                    data: [],
                };
        all_datasets[POSTEVENT_RETURN_IDX] = {
                    label: `post-event drift`,
                    backgroundColor: vizColors[POSTEVENT_RETURN_IDX % vizColors.length],
                    borderColor: vizColors[POSTEVENT_RETURN_IDX % vizColors.length],
                    borderWidth: 2,
                    pointRadius: 0,
                    fill: true,
                    spanGaps: true,
                    showLine: true,
                    data: [],
                };

        for (var i = 0; i < return_json.length; i++) {
            all_datasets[NORMAL_RETURN_IDX]["data"].push({
                x: return_json[i]["t"],
                y: return_json[i]["normal return %"]
            })
            all_datasets[POSTEVENT_RETURN_IDX]["data"].push({
                x: return_json[i]["t"],
                y: return_json[i]["mean"]
            })
        }

        setReturnData({
            datasets: all_datasets
            });
    }

    const setPnlData = (summary_json) => {
        /* use summary data (in json format) to populate historical pnl chart */
        var all_datasets = [];
        var seenTickers = {};
        var nSeenTickers = 0;

        console.log(`setting pnl data for ${JSON.stringify(summary_json)}`)

        for (var i = 0; i < summary_json["ticker"].length; i++) {
            var tk = summary_json["ticker"][i];

            if (tk === "NA") continue;

            if (!(tk in seenTickers)) {
                seenTickers[tk] = nSeenTickers;
                nSeenTickers += 1;

                all_datasets.push({
                    label: `${tk} pnl`,
                    backgroundColor: vizColors[seenTickers[tk] % vizColors.length],
                    borderColor: vizColors[seenTickers[tk] % vizColors.length],
                    borderWidth: 2,
                    pointRadius: 0,
                    fill: true,
                    spanGaps: true,
                    showLine: true,
                    data: [],
                });
            }

            all_datasets[seenTickers[tk]]["data"].push({
                x: new Date(summary_json["open date"][i]),
                y: summary_json["probable pnl"][i]
            })
        }

        setHistPnlData({
            datasets: all_datasets
            });
    }

    const plot = (time_series,events) => {
        const tickers = Object.keys(time_series);
        console.log(`tickers: ${tickers}`)

        var all_datasets = [];

        for (var i = 0; i < tickers.length; i++) {
            var tk = tickers[i];
            all_datasets.push ({
                label: `${tk} events`,
                backgroundColor: vizColors[i % vizColors.length],
                pointRadius: 4,
                data: events.filter(e=>e.ticker === tk).map(e => {
                     var dt = new Date(e.date);

                     return {x: dt,
                        y: e.price};
                })
             });
        }

        for (var i = 0; i < tickers.length; i++) {
            var tk = tickers[i];
            all_datasets.push ({
                label: `${tk}`,
                backgroundColor: vizColors[i % vizColors.length],
                borderColor: vizColors[i % vizColors.length],
                borderWidth: 2,
                pointRadius: 0,
                fill: true,
                spanGaps: true,
                showLine: true,
                data: time_series[tk].map(e => {
                     var dt = new Date(e.date);
                     return {x: dt,
                        y: e.close};
                })
             });
        }

        setTsData({
            datasets: all_datasets
            });
        setRefHook(refHook+1);
    }

    const updateTabularReturnData = (return_json, header_col) => {
        console.log(return_json);
        var return_data_header = return_json.map((x)=>x[header_col].toString());
        console.log(`return_data_header: ${return_data_header}`)
        return_data_header = ["Minutes after Event: "].concat(return_data_header);
        console.log(`now its: ${return_data_header}`)
        setReturnDataHeader(return_data_header);
        var new_return_data = {};

        console.log(`rows: ${Object.keys(return_json[0])}`)

        var rows = Object.keys(return_json[0]).filter((x)=>(x != header_col));

        new_return_data["Minutes after Event: "] = rows;

        for (var i=0; i < return_json.length; i++) {
            new_return_data[return_json[i][header_col]] = rows.map((x)=>(return_json[i][x]));
        }

        setReturnDataTabular(new_return_data);
    }

    useEffect(() => {
        fetch(`${globalData.api_root}/query/fetch/${props.queryId}`,
                {crossDomain: true,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem("token")
                    },
                }
        ).then(response=>response.json()).then(json_data=> {
            plot(json_data["data"]["time_series"], json_data["data"]["events"])
            console.log(`query_res: ${JSON.stringify(json_data["data"]["summary"])}`)
            setSummaryData(json_data["data"]["summary"]);
            setSummaryHeader(json_data["data"]["summary_header"]);
            setPnlData(json_data["data"]["summary"]);
            updateReturnData(json_data["data"]["returns"]);
            updateTabularReturnData(json_data["data"]["returns"], "t");
            setQueryStatus(json_data["status"]);
        }).catch(err=>{
            console.log("found error in query fetch:");
            console.log(err);
        })}, []);

  return (<>
    <Grid container>
      <Grid item xs={12}>
        <div style={topStyle}>
           <br />
            <Divider style={{backgroundColor: color_palette["gray"]}} />
            <Typography sx={mainHeaderStyle} ml={20}>
              Event Report
            </Typography>
            <Typography sx={descStyle} ml={20}>
              This page provides post-event performance. It also identifies whether or not there is an abnormal return or a potentially tradeable strategy.
            </Typography>
            <br />
         </div>
      </Grid>
    </Grid>
    <QueryResults queryId={props.queryId} />
   </>);
}

export default QueryResultPage;