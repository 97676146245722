import './App.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios'

import NavBar from './components/NavBar.js';
import Footer from './components/Footer.js';

import ReactGA from 'react-ga4';

import HomePage from './pages/HomePage.js';
import QueryPage from './pages/QueryPage';
import QueryLoadingPage from './pages/QueryLoadingPage';
import QueryResultPage from './pages/QueryResultPage';
import QueryFailedPage from './pages/QueryFailedPage';
import SigninPage from './pages/SigninPage';
import AboutPage from './pages/AboutPage.js';
import EventStudy from './pages/EventStudy.js';
import SubscriptionPage from './pages/SubscriptionPage.js';
import Profile from './pages/Profile.js';
import ForgotPassword from './pages/ForgotPassword.js';
import CramerPage from './pages/CramerPage.js';
import InterestRatePage from './pages/InterestRatePage.js';

import {Route, Routes, HashRouter, BrowserRouter, useParams, useLocation} from "react-router-dom";


import {Box} from "@mui/material";

function App() {
  const [getMessage, setGetMessage] = useState({})


  ReactGA.initialize("G-GNHFVXW7Y8");

  const hasTokenExpired = () => {
    const expiry_time = JSON.parse(atob(localStorage.getItem("token").split('.')[1])).exp;
    return Math.floor((new Date()).getTime()/1000) >= expiry_time;
  }

  const [loggedIn, setLoggedIn] = useState((localStorage.getItem("token") !== null) && (localStorage.getItem("token") !== "undefined") && !hasTokenExpired());

  useEffect(() => {
      window.addEventListener("storage", ()=>{
        setLoggedIn(localStorage.getItem("token") !== null);
       });
  }, []);
  return (
    <div className="App"  style={{alignContent: "top"}}>
    <Box fixed className="App">
    <HashRouter>
      <PageTracker />
      <NavBar />
      <Box style={{minHeight: "100vh"}}>
      <Routes>
        {(loggedIn) && <>
        <Route exact path="/" element={<HomePage />}></Route>
        <Route path="/signin" element={<SigninPage />}></Route>
        <Route path="/query" element={<QueryPage />}></Route>
        <Route path="/query/loading/:queryId" element={<QueryLoadingPageWrapper />}></Route>
        <Route path="/query/result/:queryId" element={<QueryResultPageWrapper />}></Route>
        <Route path="/query/resultWithInput/:queryId" element={<QueryPageWrapper />}></Route>
        <Route path="/query/cramer" element={<CramerPage />}></Route>
        <Route path="/query/interestrate" element={<InterestRatePage />}></Route>
        <Route path="/query/failed" element={<QueryFailedPage />}></Route>
        <Route path="/subscriptions" element={<SubscriptionPage />}></Route>
        <Route path="/profile" element={<Profile />}></Route>
        <Route path="/about" element={<AboutPage />}></Route>
        <Route path="/eventstudies" element={<EventStudy />}></Route>
        </>}
        {(!loggedIn) && <>
        <Route exact path="/" element={<HomePage />}></Route>
        <Route path="/signin" element={<SigninPage />}></Route>
        <Route path="/about" element={<AboutPage />}></Route>
        <Route path="/eventstudies" element={<EventStudy />}></Route>
        <Route path="/forgot_password" element={<ForgotPassword />}></Route>
        <Route path="/*" element={<SigninPage />}></Route>
        </>}
      </Routes>
      </Box>

      <Footer />
    </HashRouter>
    </Box>
    </div>
  );
}

function PageTracker() {
  const location = useLocation();

  useEffect(() => {
      ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search});
  }, [location]);
}

function QueryLoadingPageWrapper() {
  let {queryId} = useParams();
  return (
    <QueryLoadingPage queryId={queryId} />
  )
}
function QueryResultPageWrapper() {
  let {queryId} = useParams();
  return (
    <QueryResultPage queryId={queryId} />
  )
}
function QueryPageWrapper() {
  let {queryId} = useParams();
  return (
    <QueryPage queryId={queryId} />
  )
}

export default App;
