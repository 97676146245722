import '../App.css';

import React, { useEffect, useState } from 'react';
import {Card, Typography, Button, Divider, CircularProgress, Box} from "@mui/material";

import { useNavigate } from "react-router-dom";
import {color_palette, font_palette} from '../style/StylePalette.js';


import {globalData} from '../Global.js';

import QueryInput from '../components/QueryInput.js';

import axios from 'axios';

import logo_simple from '../static/logo_simple.svg';

function  QueryLoading  (props){
  const navigate = useNavigate();

  const MAX_FAILED_FETCHES = 5;

  const [nFailedFetches, setNFailedFetches] = useState(0);

    var pollInterval = -1;
    useEffect(() => {
        setNFailedFetches(0);
        pollInterval = setInterval(()=>{
            console.log(`polling for ${props.queryId}`)
            if (props.queryStatus === "waiting"){
                fetch(`${globalData.api_root}/query/check/${props.queryId}`,
                        {crossDomain: true,
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem("token")
                        },}
                ).then(response=>{
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                }
                ).then(json_data=> {
                    console.log(`json data: ${JSON.stringify(json_data)}`)
                    props.setQueryStatus(json_data["status"]);
                    console.log(`query status: ${props.queryStatus}`)
                    if (json_data["status"] === "fulfilled_or_failed"){
                        clearInterval(pollInterval);
                    }
                }).catch(err=>{
                    setNFailedFetches(nFailedFetches+1);
                    if (nFailedFetches === MAX_FAILED_FETCHES) {
                        setNFailedFetches(0);
                        props.setQueryStatus("failed");
                        console.log("found error in query fetch:");
                        console.log(err);
                        clearInterval(pollInterval);
                    }
                })
            }
        }, 10000);

     }, []);

  return (<>
    {(props.queryStatus == "waiting") &&
        (<div>
            <Box mt={{xs: 1, md: 3, xl: 5}} position="relative" display="inline-flex">
            <CircularProgress size={100} mt={100} style={{color: color_palette["primary"]}} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                        <img src={logo_simple} style={{width: 50}} />
            </Box>
            </Box>
            <Box mt={{xs: 1, md: 3, xl: 5}} >
            <Typography>Processing Query, Please Wait...</Typography>
            </Box>
        </div>)
    }
    {(props.queryStatus == "failed" || props.queryStatus == "overwrite") &&
        (<Typography>Query Failed. Please try again..</Typography>)
    }
    <br /><br />
   </>);
}

export default QueryLoading;