import '../App.css';

import React from 'react';
import {Grid, Typography, Box} from "@mui/material";

import {color_palette, font_palette} from '../style/StylePalette.js';

import Signup from '../components/Signup.js';
import Signin from '../components/Signin.js';

import hero from '../static/hero.jpg';
import about from '../static/about.png';

function  SubscriptionPage  (props) {

 const headerStyle = {
    fontFamily: font_palette["header"],
    color: color_palette["white"],
    fontSize: {
        'sm': 40,
        'lg': 68,
    },
    fontWeight: "bolder",
 }

 const headerStyleBlack = {
    fontFamily: font_palette["header"],
    color: color_palette["black"],
    fontSize: {
        'sm': 40,
        'lg': 68,
    },
    fontWeight: "bolder",
    textAlign: "left",
 }
 const descStyle = {
    fontFamily: font_palette["header"],
    color: color_palette["black"],
    fontSize: {
        'sm': 24,
        'lg': 36,
    },
    textAlign: "left",
    fontWeight: "bold",
 }
 const descLinkStyle = {
    fontFamily: font_palette["header"],
    color: color_palette["primary"],
    fontSize: {
        'sm': 24,
        'lg': 36,
    },
    textAlign: "left",
    fontWeight: "bold",

        cursor: "pointer",
 }
 const descStyleBlack = {
    fontFamily: font_palette["header"],
    color: color_palette["white"],
    fontSize: {
        'sm': 24,
        'lg': 36,
    },
    textAlign: "left",
    fontWeight: "bold",
 }
 const descStylePrimary = {
    fontFamily: font_palette["header"],
    color: color_palette["primary"],
    fontSize: {
        'sm': 24,
        'lg': 36,
    },
    textAlign: "left",
    fontWeight: "bold",
 }
 const subDescStyle = {
    fontFamily: font_palette["header"],
    color: color_palette["black"],
    fontSize: 18,
    textAlign: "left",
 }
 const buttonStyle = {
    fontFamily: font_palette["text"],
    color: color_palette["white"],
    backgroundColor: color_palette["primary"],
    fontSize: 12,
    width: 300
 }

  const [signInRatherThanSignUp, setSignInRatherThanSignUp] = React.useState(true);

  return (
  <Grid container>
      <Grid item ml={15} xs={12} mt={8} mr={15}>
        <Typography sx={headerStyleBlack}>Coming soon!</Typography>
        <br />
        <Typography sx={descStyle}>Subscribe to Anagami.ai to get regular alerts about any selected events...</Typography>

      </Grid>
    </Grid>
);
}

export default SubscriptionPage;
