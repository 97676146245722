import '../App.css';

import React from 'react';
import {Grid, Button, Typography, Link} from "@mui/material";

import {color_palette, font_palette} from '../style/StylePalette.js';

import { useNavigate } from "react-router-dom";

import { globalData, contactData } from '../Global.js';

import Signup from '../components/Signup.js';
import Signin from '../components/Signin.js';

import hero from '../static/hero.jpg';

function  SigninPage  (props) {
 const headerStyle = {
    fontFamily: font_palette["header"],
    color: color_palette["black"],
    fontSize: 36,
    fontWeight: "bolder",
 }
 const descStyle = {
    fontFamily: font_palette["header"],
    color: color_palette["black"],
    fontSize: 36,
 }
 const buttonStyle = {
    fontFamily: font_palette["text"],
    color: color_palette["white"],
    backgroundColor: color_palette["primary"],
    fontSize: 12,
    width: "40%",
 }
    const labelStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["gray"],
        fontSize: 16,
     }
    const linkStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["link"],
        fontSize: 16,
        cursor: "pointer",
     }

  const navigate = useNavigate();

 const forgotPassword = () => {
    navigate("/forgot_password");
 }

  const [signInRatherThanSignUp, setSignInRatherThanSignUp] = React.useState(true);

  return (<>
  <Grid container spacing={6}>
      <Grid item xs={12} mt={6}>
        <Typography style={headerStyle}>Sign {signInRatherThanSignUp ? "in" : "up"} with Email</Typography>
      </Grid>

      {signInRatherThanSignUp
       && <>
       <Signin />
       <Grid item xs={12}>
       <Typography sx={labelStyle}>Don't have an account?{" "}<Typography sx={linkStyle} display="inline" onClick={()=>setSignInRatherThanSignUp(false)}>Sign up</Typography></Typography>
       </Grid>
       <Grid item xs={12}>
       <Typography sx={linkStyle} onClick={()=>{forgotPassword()}}>Forgot Password?</Typography>
       </Grid>
       </>}
      {(!signInRatherThanSignUp)
       && <>
       <Signup />
       <Grid item xs={12}>
       <Typography sx={labelStyle}>Already have an account?{" "}<Typography sx={linkStyle} display="inline" onClick={()=>setSignInRatherThanSignUp(true)}>Sign in</Typography></Typography><br />
       <Typography sx={labelStyle}><Typography sx={linkStyle} display="inline" onClick={() => {window.location.href = (`mailto:${contactData.default_email}`)}} >Contact Us</Typography> to receive a public beta code</Typography>
       </Grid>

       </>}
       <Grid item xs={12}>
       <br /><br />
       </Grid>
  </Grid>
</>);
}

export default SigninPage;
