import '../App.css';

import React from 'react';
import {Grid, Typography, Box, Divider} from "@mui/material";

import {color_palette, font_palette} from '../style/StylePalette.js';

import { globalData, contactData } from '../Global.js';
import Signup from '../components/Signup.js';
import Signin from '../components/Signin.js';

import hero from '../static/hero.jpg';
import city from '../static/city.png';
import logo_simple from '../static/logo_simple.svg';

function  AboutPage  (props) {

 const headerStyle = {
    fontFamily: font_palette["header"],
    color: color_palette["white"],
    fontSize: {
        'xs': 40,
        'lg': 68,
    },
    fontWeight: "bolder",
 }

 const headerStyleBlack = {
    fontFamily: font_palette["header"],
    color: color_palette["black"],
    fontSize: {
        'xs': 24,
        'lg': 48,
    },
    fontWeight: "bolder",
    textAlign: "left",
 }
 const descStyle = {
    fontFamily: font_palette["header"],
    color: color_palette["black"],
    fontSize: {
        'xs': 18,
        'lg': 24,
    },
    textAlign: "left",
    //fontWeight: "bold",
 }
 const descLinkStyle = {
    fontFamily: font_palette["header"],
    color: color_palette["primary"],
    fontSize: {
        'xs': 18,
        'lg': 24,
    },
    textAlign: "left",
    fontWeight: "bold",

        cursor: "pointer",
 }
 const descEmphasisStyle = {
    fontFamily: font_palette["header"],
    //color: color_palette["complement1"],
    fontSize: {
        'xs': 18,
        'lg': 24,
    },
    textAlign: "left",
    fontWeight: "bold",
 }
 const descStyleBlack = {
    fontFamily: font_palette["header"],
    color: color_palette["white"],
    fontSize: {
        'xs': 24,
        'lg': 36,
    },
    textAlign: "left",
    fontWeight: "bold",
 }
 const descStylePrimary = {
    fontFamily: font_palette["header"],
    color: color_palette["primary"],
    fontSize: {
        'xs': 24,
        'lg': 36,
    },
    textAlign: "left",
    fontWeight: "bold",
 }
 const subDescStyle = {
    fontFamily: font_palette["header"],
    color: color_palette["black"],
    fontSize: 18,
    textAlign: "left",
 }
 const buttonStyle = {
    fontFamily: font_palette["text"],
    color: color_palette["white"],
    backgroundColor: color_palette["primary"],
    fontSize: 12,
    width: 300
 }

  const [signInRatherThanSignUp, setSignInRatherThanSignUp] = React.useState(true);

  return (
  <Grid container>

      {/*<Grid item xs={12} lg={4}>
        <Box ml={15} mt={10} display={{lg: "block", xs: "none"}}>
        <img src={city} style={{width: "100%"}}/>
        </Box>
      </Grid>*/}
      <Grid item xs={12} lg={8}>
      <Box ml={{xs: 5, md: 15}} mt={8} mr={{xs: 5, md: 15}} mb={8}>

        <Typography sx={headerStyleBlack}>About Us</Typography>
        <br />
        <Typography sx={descStyle}><img src={logo_simple} style={{width: 25, verticalAlign: "middle"}} />{" "}anagami.ai is a tool to analyse impact of events and to design trading strategies based off of this analysis</Typography>
            <Typography sx={descStyle}><img src={logo_simple} style={{width: 25, verticalAlign: "middle"}} />{" "}Expect more features soon, such as event subscriptions, more tickers, more events, and custom dashboards</Typography>
            <br /><br />

        </Box>
      </Grid>

      <Grid item xs={12} lg={12} mt={8}>
        <Divider sx={{backgroundColor: color_palette["black"]}} />
      </Grid>

      <Grid item xs={12}>
      <Box ml={{xs: 5, md: 15}} mt={8} mr={{xs: 5, md: 15}} mb={8}>
        <Typography sx={headerStyleBlack}>Contact Us</Typography>
        <br />
        <Typography sx={descStyle}><img src={logo_simple} style={{width: 25, verticalAlign: "middle"}} />{" "}Have any questions, comments, or concerns? <Typography sx={descLinkStyle} display="inline" onClick={() => {window.location.href = (`mailto:${contactData.default_email}`)}} >Email Us</Typography></Typography>
        <br /><br />

        </Box>
      </Grid>
    </Grid>
);
}

export default AboutPage;
