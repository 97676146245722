import '../App.css';

import React from 'react';
import {Grid, Button, Typography, Box, Divider} from "@mui/material";

import Carousel from 'react-material-ui-carousel'

import {color_palette, font_palette} from '../style/StylePalette.js';

import { useNavigate } from "react-router-dom";

import BenefitCard from '../components/BenefitCard.js';

import ProblemCard from '../components/ProblemCard.js';

import hero from '../static/hero.jpg';
import about from '../static/about.png';
import release_header from '../static/release_header.png';
import logo_simple from '../static/logo_simple.svg';

function  HomePage  (props){
 const headerStyle = {
    fontFamily: font_palette["header"],
    color: color_palette["white"],
    fontSize: {
        'xs': 28,
        'lg': 54,
    },
    fontWeight: "bolder",
 }
 const descStyle = {
    fontFamily: font_palette["header"],
    color: color_palette["black"],
    fontSize: {
        'xs': 20,
        'md': 24,
        'lg': 30,
    },
    textAlign: "left",
 }
 const descStyleBold = {
    fontFamily: font_palette["header"],
    color: color_palette["black"],
    fontSize: {
        'xs': 20,
        'md': 24,
        'lg': 36,
    },
    textAlign: "left",
    fontWeight: "bold",
 }
 const descLinkStyle = {
    fontFamily: font_palette["header"],
    color: color_palette["primary"],
    fontSize: {
        'xs': 20,
        'md': 24,
        'lg': 30,
    },
    textAlign: "left",
    fontWeight: "bold",

        cursor: "pointer",
 }
 const descStyleBlack = {
    fontFamily: font_palette["header"],
    color: color_palette["white"],
    fontSize: {
        'xs': 20,
        'md': 24,
        'lg': 36,
    },
    textAlign: "left",
    fontWeight: "bold",
 }
 const descStyleBlackLink = {
    fontFamily: font_palette["header"],
    color: color_palette["primary"],
    fontSize: {
        'xs': 20,
        'md': 24,
        'lg': 36,
    },
    textAlign: "left",
    fontWeight: "bold",

        cursor: "pointer",
 }
 const descStylePrimary = {
    fontFamily: font_palette["header"],
    color: color_palette["primary"],
    fontSize: {
        'xs': 20,
        'md': 24,
        'lg': 36,
    },
    textAlign: "left",
 }
 const subDescStyle = {
    fontFamily: font_palette["header"],
    color: color_palette["black"],
    fontSize: 18,
    textAlign: "left",
 }
 const buttonStyle = {
    fontFamily: font_palette["text"],
    color: color_palette["white"],
    backgroundColor: color_palette["primary"],
    fontSize: 12,
    maxWidth: 300,
    minWidth: 250,
 }
  const navigate = useNavigate();

  return (
  <Grid container>
      <Grid item xs={12}>
        {/*<img src={hero} style={{display: "flex", width: "100%", maxHeight: "60%", objectFit: "cover"}}/>*/}
        {/*<div style={{position: "absolute", top: "30%", left: "10%", width: "30%", textAlign: "left"}}>*/}
        <div style={{backgroundImage: `url(${hero})`, backgroundRepeat: "no-repeat", backgroundSize: "100%", maxHeight: "80%", textAlign: "left"}}>
            <Box sx={{display: {xs: "none", lg: "block"}}}>
            <br /><br /><br /><br /><br /><br />
            </Box>
            <Box sx={{display: {xs: "none", sm: "block"}}}>
            <br /><br />
            </Box>
            <Box ml={{xs:5, md: 25}} mr={{xs:5, md: 25}}>
            <Typography sx={headerStyle}>Trade economic releases with<br /> AI-based intelligence</Typography>
            <br />
            <Button onClick={()=>navigate("/query")} style={buttonStyle}>Get started</Button>
            </Box>
            <Box>
            <br /><br /><br />
            </Box>
            <Box sx={{display: {xs: "none", sm: "block"}}}>
            <br /><br />
            </Box>
            <Box sx={{display: {xs: "none", lg: "block"}}}>
            <br /><br /><br /><br /><br /><br /><br />
            </Box>
        </div>
      </Grid>

      <Grid item xs={12} md={6} mt={0}>
        <img style={{width: "75%"}} src={about} />
      </Grid>

      <Grid item xs={12} md={6} ml={{xs: 5, md:0}} mt={{xs: 5, md: 0}}>
        <Typography mr={8} sx={descStyleBold}>Anagami means enlightenment<br /><br /></Typography>
         <Typography mr={8} sx={descStyle}>

         In trading, the question of how a particular release, such as
            Fed hiking rates, inflation data, or a crypto exchange being
            hacked affects stock and cryptocurrency prices arises on a
            daily basis

         <br /><br />

        Our platform helps investors to tackle this problem with a
            data-driven approach based on a scientific methodology
            known as <Typography onClick={()=>{navigate("/#/eventstudies")}} sx={descLinkStyle} display="inline"> Event Studies</Typography>,
                originally developed in
            1930s to analyse impact of stock splits; and machine
            learning techniques

        </Typography>
      </Grid>
      <Grid item xs={12} mt={8}>
        <div style={{backgroundImage: `linear-gradient(to bottom right, black, black, black, ${color_palette["primary"]})`, backgroundSize: "100%", maxHeight: "100%", textAlign: "left"}}>
        <Box ml={{xs: 5, md: 15}} mr={{xs: 5, md: 15}}>
        <Typography sx={descStyleBlack}><br /><br />
        "My job as an investor is to figure out what will happen, not what should happen"
        </Typography>
        <Typography sx={descStyleBlack} ml={5}><br />
        — <it>David Einhorn, Greenlight Capital</it>
        </Typography>
        <br /><br /><br /><br /><br />
        </Box>

        </div>
      </Grid>

      <Grid item xs={12} md={6} ml={{xs: 5, md: 15}} mt={6}>
        <Typography mr={8} sx={descStyleBold}>Research Notes<br /><br /></Typography>
         <Typography mr={8} sx={descStyle}>
            See our professional anagami.ai-powered research notes below:

         <br /><br />
        {/*<Typography sx={descStyle}><img src={logo_simple} style={{width: 30}} />{" "}Click <Typography onClick={()=>{window.location.href = "rate_hikes_spy_dec22.pdf"}} sx={descLinkStyle} display="inline">here</Typography> for our analysis on fed rate hikes</Typography>*/}
        <Typography sx={descStyle}><img src={logo_simple} style={{width: 30}} />{" "}Click <Typography onClick={()=>{window.location.href = "eia_oil_jan23.pdf"}} sx={descLinkStyle} display="inline">here</Typography> for our analysis on EIA inventory releases and oil prices</Typography>
        <Typography sx={descStyle}><img src={logo_simple} style={{width: 30}} />{" "}Click <Typography onClick={()=>{window.location.href = "natgas_dip_jan23.pdf"}} sx={descLinkStyle} display="inline">here</Typography> for our analysis on the dip in natural gas prices</Typography>

        </Typography>
      </Grid>

      <Grid item xs={12} md={4} lg={4} xl={5} mt={6} mr={0}>
        <img style={{width: "85%"}} src={release_header} />
      </Grid>

      <Grid item xs={12}>
        <br /><br />
        <Divider />
      </Grid>


      <Grid item md={4} lg={3} xs={12} ml={{xs: 6, md: 10, lg: 15}} mr={{xs: 6, sm:0}} mt={8} mb={8}>
        <Typography sx={descStyleBold}>Why use anagami.ai?</Typography>
        <br />
        <Carousel style={{height: 250}}>
            <Box style={{display: "flex"}} spacing={{xs:0, md:3}}>
            <BenefitCard name={"Scientific Basis"}>
            Event study is a well-established
                scientific framework, used since 1930s<br /><br />

                Originally developed to analyse
                impact of stock splits, the method has
                been used by some of the world’s best
                economists to analyse a wide range of
                economic releases<br /><br />

                Read more about it <Typography display="inline" sx={{color: color_palette["primary"], cursor: "pointer"}} onClick={navigate("/eventstudies")}>here</Typography>
            </BenefitCard>
            </Box>
            <Box style={{display: "flex"}} spacing={{xs:0, md:3}}>
            <BenefitCard name={"No Code"}>
            Conducting event studies can be done in excel or manually in python, however it takes a lot of manual work<br /><br />
            anagami.ai does this analysis in seconds at the click of a button<br /><br />
            Additionally, our approach to event studies is supplemented by machine learning methods to increase accuracy
            </BenefitCard>
            </Box>
            <Box style={{display: "flex"}} spacing={{xs:0, md:3}}>
            <BenefitCard name={"Numeric Conclusions"}>
            anagami.ai summarises the analysis into actionable intelligence for trading:  'long'/'short', or 'inconclusive' recommendation
            </BenefitCard>
            </Box>
        </Carousel>
      </Grid>
      <Grid item md={5} lg={6} xs={12} ml={{xs: 6, md: 10, lg: 15}} mr={{xs: 6, md:0}} mt={8} mb={8}>
        <Typography sx={descStyleBold}>How can anagami.ai help?</Typography>
        <br />
        <Carousel style={{minHeight: 350}}>
            <Box style={{display: "flex"}} spacing={3}>
            <ProblemCard title={"The next Fed interest rate meeting is coming up.."}>
            Find out what is the likely impact on S&P500, precious
            metals, or Ethereum, with a click of a button
            <br /><br />
            <Button onClick={()=>navigate("/query/interestrate")} style={buttonStyle}>Learn more..</Button>
            </ProblemCard>
            </Box>
            <Box style={{display: "flex"}} spacing={3}>
            <ProblemCard title={"You might have seen people saying 'Inverse Cramer' is a good strategy"}>
            Learn about how often ‘Inverse Cramer’ actually works and what is the optimal time to hold positions under this strategy
            <br /><br />
            <Button onClick={()=>navigate("/query/cramer")} style={buttonStyle}>Learn more..</Button>
            </ProblemCard>
            </Box>
        </Carousel>
        </Grid>
  </Grid>
  );
}

export default HomePage;
