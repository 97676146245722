import '../App.css';

import React, { useEffect, useState } from 'react';
import {Card, Typography, Button, Divider, Grid, Line} from "@mui/material";
import {color_palette, font_palette} from '../style/StylePalette.js';
import {globalData} from '../Global.js';
import QueryInput from '../components/QueryInput.js';

import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  TimeScale,
  Legend,
  LineController,
} from "chart.js";
import {uk} from 'date-fns/locale';
import "chartjs-adapter-date-fns";

import { Scatter } from "react-chartjs-2";


import axios from 'axios';

function  TimeSeriesChart  (props) {
    ChartJS.register(TimeScale, LinearScale, PointElement, LineElement, Tooltip, Legend, LineController);


    const options = {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    boxWidth: 40,
                    font: {
                        size: 20,
                    }
                }
            },
            decimation: {
                enabled: true,
                algorithm: 'lttb',
            }
        },
        scales: {
          x: {
            type: 'time',
            time: {
              // Luxon format string
              tooltipFormat: 'MMM yy'
            },
            title: {
              display: true,
              text: 'Date'
            },
            ticks: {
                autoSkip: true,
                maxRotation: 0,
                minRotation: 0
            },
          },
          y: {
            beginAtZero: props.beginAtZero,
            title: {
                display: true,
                text: props.yLabel,
            },
            ticks: props.ptg ? {
                callback: function(label, index, labels) {
                    return (label*100).toFixed(1)+'%';
                }
            } : {},
          },
        },
        interaction: {
            mode: 'nearest'
        },
        onClick: (e) => {
            console.log("clicked chart");
            console.log(`e: ${JSON.stringify(e)}`)
        },
        normalized: true,
      };

  return (<>
      <Scatter height={"100%"} data={props.data} options={options} />
   </>);
}

export default TimeSeriesChart;
