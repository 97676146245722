import '../App.css';

import React, { useEffect, useState } from 'react';
import {Card, Typography, Button, Divider, Grid, TextField, Select, MenuItem} from "@mui/material";
import { useNavigate } from "react-router-dom";

import {globalData} from '../Global.js';

import {color_palette, font_palette} from '../style/StylePalette.js';

import Signup from '../components/Signup.js';
import Signin from '../components/Signin.js';

import hero from '../static/hero.jpg';
import {checkValidPassword, checkValidEmail, minimumPasswordLength, maximumPasswordLength} from '../util/UserValidation.js';

function  ChangePassword  (props) {
    const navigate = useNavigate();

    const [passwordStatus, setPasswordStatus] = useState("");

    const [email, setEmail] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");

    const [incorrectPassword, setIncorrectPassword] = useState(false);
    const [incorrectPasswordText, setIncorrectPasswordText] = useState("");

    const [signupDisabled, setSignupDisabled] = useState(true);

     const headerStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["black"],
        fontSize: 36,
        fontWeight: "bolder",
     }
     const descStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["black"],
        fontSize: 16,
     }
     const buttonStyle = {
        fontFamily: font_palette["text"],
        color: color_palette["white"],
        backgroundColor: signupDisabled ? color_palette["highlight_gray"] : color_palette["highlight"],
        fontSize: 12,
        width: "100%",
        maxWidth: "20%",
     }
    const labelStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["gray"],
        fontSize: 16,
     }
    const linkStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["link"],
        fontSize: 16,
        cursor: "pointer",
     }

    const checkDisabled = () => {
        if (checkValidPassword(newPassword)) {
            setSignupDisabled(false);
        } else {
            setSignupDisabled(true);
        }
    }

    useEffect(() => {
        if (!checkValidPassword(newPassword) && newPassword.length > 0) {
            setIncorrectPassword(true);
            if (newPassword.length < minimumPasswordLength) {
                setIncorrectPasswordText(`Password must be at least ${minimumPasswordLength} characters`);
            } else if (newPassword.length > maximumPasswordLength) {
                setIncorrectPasswordText(`Password must be at most ${maximumPasswordLength} characters`);
            } else {
                setIncorrectPasswordText(`Invalid password`);
            }

        } else {
            setIncorrectPassword(false);
            setIncorrectPasswordText("");
        }
        checkDisabled();
    }, [newPassword]);

    const changePassword = () => {
        if (!checkValidPassword(newPassword)) {
            setIncorrectPassword(true);
            if (newPassword.length < minimumPasswordLength) {
                setIncorrectPasswordText(`Password must be at least ${minimumPasswordLength} characters`);
            } else if (newPassword.length > maximumPasswordLength) {
                setIncorrectPasswordText(`Password must be at most ${maximumPasswordLength} characters`);
            } else {
                setIncorrectPasswordText(`Invalid password`);
            }

            return;
        }
        fetch(`${globalData.api_root}/user/change_password`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem("token")
                    },
                    crossDomain: true,
                    body: JSON.stringify({
                            username: email,
                            old_password: oldPassword,
                            new_password: newPassword,
                    })}).then(response=>{
                        if (!response.ok) {
                            throw new Error(`failed (${response.status})`)
                        }
                        return response.json()
                    }).then(json_data=> {
                        localStorage.setItem("token", json_data["access_token"]);
                        window.dispatchEvent(new Event("storage"));
                        navigate("/query");
                    }).catch(err=>{
                        console.log("found error:");
                        console.log(err);
                        setPasswordStatus("Sign in failed, please try again...");
                    });
    }

  return (<>
      <Grid item xs={12} mt={6}>
        <Typography style={headerStyle}>Update Password</Typography>
      </Grid>

      <Grid item xs={12}>
            <Typography sx={{color: "red"}}>
                {passwordStatus}
            </Typography>

            <Typography sx={labelStyle}>
              Email*
            </Typography>
            <TextField value={email} sx={{maxWidth: {lg: "30%", md: "40%", xs: "80%"}, width: "100%"}} onKeyDown={(e)=>{if (e.key==="Enter"){changePassword()}}} onChange={(e) => setEmail(e.target.value)}>
            </TextField>


            <Typography sx={labelStyle} mt={2}>
              Old Password*
            </Typography>
            <TextField type="password" sx={{maxWidth: {lg: "30%", md: "40%", xs: "80%"}, width: "100%"}} onKeyDown={(e)=>{if (e.key==="Enter"){changePassword()}}} value={oldPassword} onChange={(e) => setOldPassword(e.target.value)}>
            </TextField>


            <Typography sx={labelStyle} mt={2}>
              New Password*
            </Typography>
            <TextField
                error={incorrectPassword}
                helperText={incorrectPasswordText}
                type="password"
                sx={{maxWidth: {lg: "30%", md: "40%", xs: "80%"}, width: "100%"}}
                onKeyDown={(e)=>{if (e.key==="Enter"){changePassword()}}}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}>
            </TextField>
      </Grid>
      <Grid item xs={12}>
        <Button style={buttonStyle} onClick={changePassword}>
          Submit
        </Button>
        <br /><br /><br />
      </Grid>
</>);
}

export default ChangePassword;
