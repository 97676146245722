import '../App.css';

import React, { useEffect, useState } from 'react';
import {Tabs, Tab, Grid, Box} from "@mui/material";
import { useNavigate } from "react-router-dom";

import {globalData} from '../Global.js';

import {color_palette, font_palette} from '../style/StylePalette.js';

import AccountStatus from '../components/AccountStatus.js';
import ChangePassword from '../components/ChangePassword.js';

import hero from '../static/hero.jpg';

function  ProfilePage  (props) {
  const [tabValue, setTabValue] = useState(0);

  const changeTab = (event, newValue) => {
    setTabValue(newValue);
  }

  return (<Box ml={5} mr={5}>
   <Grid container>
        <Grid item xs={2}>
        <Box mt={0} style={{height: "100vh"}} sx={{borderRight: "1px solid" + color_palette["black"]}}>
            <br />
        <Tabs value={tabValue} onChange={changeTab} orientation="vertical">
            <Tab label="Account Status" />
            <Tab label="Change Password" />
        </Tabs>
        </Box>
        </Grid>
    <Grid item xs={10} mt={5}>

      <Grid container spacing={6}>
       { tabValue === 0 && <AccountStatus /> }
        { tabValue === 1 && <ChangePassword /> }
      </Grid>
    </Grid>
    </Grid>
  </Box>);
}

export default ProfilePage;
