import '../App.css';

import React, { useEffect, useState } from 'react';
import {Card, Typography, Button, Divider, CircularProgress, Box} from "@mui/material";

import { useNavigate } from "react-router-dom";
import {color_palette, font_palette} from '../style/StylePalette.js';


import {globalData} from '../Global.js';

import QueryLoading from '../components/QueryLoading.js';

import axios from 'axios';

import logo_simple from '../static/logo_simple.svg';

function  QueryLoadingPage  (props){
  const navigate = useNavigate();

    const [queryStatus, setQueryStatus] = React.useState("waiting");


    var pollInterval = -1;
    useEffect(() => {
        if (queryStatus === "fulfilled") {
            console.log("fulfilled...")
            console.log(`clearing: ${pollInterval}`)
            clearInterval(pollInterval);
            navigate(`/query/result/${props.queryId}`);
        } else if (queryStatus !== "waiting") {
            navigate(`/query/failed`);
        }
     }, [queryStatus]);

  return (<>
    <QueryLoading queryStatus={queryStatus} setQueryStatus={setQueryStatus} queryId={props.queryId} />
   </>);
}

export default QueryLoadingPage;