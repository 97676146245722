import '../App.css';

import React from 'react';
import {Grid, Typography, Box, Divider} from "@mui/material";

import {color_palette, font_palette} from '../style/StylePalette.js';

import Signup from '../components/Signup.js';
import Signin from '../components/Signin.js';

import hero from '../static/hero.jpg';
import about from '../static/about.png';
import city from '../static/city.png';
import logo_simple from '../static/logo_simple.svg';
import stonks from '../static/stonks.png';
import stonks2 from '../static/stonks2.png';



function  EventStudy  (props) {

 const headerStyle = {
    fontFamily: font_palette["header"],
    color: color_palette["white"],
    fontSize: {
        'xs': 40,
        'lg': 68,
    },
    fontWeight: "bolder",
 }

 const headerStyleBlack = {
    fontFamily: font_palette["header"],
    color: color_palette["black"],
    fontSize: {
        'xs': 24,
        'lg': 48,
    },
    fontWeight: "bolder",
    textAlign: "left",
 }
 const descStyle = {
    fontFamily: font_palette["header"],
    color: color_palette["black"],
    fontSize: {
        'xs': 18,
        'lg': 24,
    },
    textAlign: "left",
    //fontWeight: "bold",
 }
 const descLinkStyle = {
    fontFamily: font_palette["header"],
    color: color_palette["primary"],
    fontSize: {
        'xs': 18,
        'lg': 24,
    },
    textAlign: "left",
    fontWeight: "bold",

        cursor: "pointer",
 }
 const descEmphasisStyle = {
    fontFamily: font_palette["header"],
    //color: color_palette["complement1"],
    fontSize: {
        'xs': 18,
        'lg': 24,
    },
    textAlign: "left",
    fontWeight: "bold",
 }
 const descStyleBlack = {
    fontFamily: font_palette["header"],
    color: color_palette["white"],
    fontSize: {
        'xs': 24,
        'lg': 36,
    },
    textAlign: "left",
    fontWeight: "bold",
 }
 const descStylePrimary = {
    fontFamily: font_palette["header"],
    color: color_palette["primary"],
    fontSize: {
        'xs': 24,
        'lg': 36,
    },
    textAlign: "left",
    fontWeight: "bold",
 }
 const subDescStyle = {
    fontFamily: font_palette["header"],
    color: color_palette["black"],
    fontSize: 18,
    textAlign: "left",
 }
 const buttonStyle = {
    fontFamily: font_palette["text"],
    color: color_palette["white"],
    backgroundColor: color_palette["primary"],
    fontSize: 12,
    width: 300
 }

  const [signInRatherThanSignUp, setSignInRatherThanSignUp] = React.useState(true);

  return (
  <Grid container>

      <Grid item xs={12} md={3}>
        <Box ml={15} mr={{md: 0, xs: 15}} mt={{md: 15, xs: 5}} display={{lg: "block", xs: "none"}}>
        <img src={stonks2} style={{width: "100%"}}/>
        </Box>
      </Grid>

      <Grid item xs={12} lg={9}>
      <Box ml={{xs: 5, md: 15}} mt={8} mr={{xs: 5, md: 15}} mb={8}>
        <Typography sx={headerStyleBlack}>What are Event Studies?</Typography><br />
        <Typography sx={descStyle}><img src={logo_simple} style={{width: 25, verticalAlign: "middle"}} />
        {" "}In scientific research, the method dealing with such questions is <Typography sx={descEmphasisStyle} display="inline">Event Study</Typography>,
            first introduced in 1933 to study how stocks performed after stock splits, and modern form of the analysis was invented in 1969 by Eugene Fama.
            Event Study takes a <Typography sx={descEmphasisStyle} display="inline">series of dates</Typography> as input and produces an analysis of the average post-event performance of the security under review. </Typography>
        <br />
        <Typography sx={descStyle}><img src={logo_simple} style={{width: 25, verticalAlign: "middle"}} />
        {" "}While the theoretical framework for Event Study is well established with over 50 years of publications,
        the finance industry continues to rely heavily on answering such questions using qualitative analysis, because
        tools tailored for academic research do not fit in efficiently into the workflow of a finance professions.
        </Typography>
        <br />
        <Typography sx={descStyle}><img src={logo_simple} style={{width: 25, verticalAlign: "middle"}} />
        {" "}The classic format of <Typography sx={descEmphasisStyle} display="inline">Event Study</Typography> consists of
        laying out the post-event return after each occurrence of an event
        and computing average post-event return. This then gets normalized by deducting an assumed normal market return,
        and a test statistic is computed to identify whether an abnormal return is present.
        </Typography>
        <Typography sx={descStyle}><img src={logo_simple} style={{width: 25, verticalAlign: "middle"}} />
        {" "}Learn more from <Typography sx={descLinkStyle} onclick={()=>{window.location.href="https://www.investopedia.com/terms/e/eventstudy.asp#:~:text=over%20his%20career.-,What%20Is%20an%20Event%20Study%3F,react%20to%20a%20given%20event"}} display="inline">Investopedia</Typography>...
        </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} lg={12}>
        <Divider sx={{backgroundColor: color_palette["black"]}} />
      </Grid>

      <Grid item xs={12} lg={9}>
      <Box ml={{xs: 5, md: 15}} mt={8} mr={{xs: 5, md: 15}} mb={8}>


        <Typography sx={headerStyleBlack}>Historical Use of Event Studies</Typography>
        <br />
        <Typography sx={descStyle}><img src={logo_simple} style={{width: 25, verticalAlign: "middle"}} />
        {" "}In prior literature, the main usage of Event Studies has been around the Efficient Market Hypothesis (EMH),
        a concept developed in 1970, also by Eugene Fama, which states that markets incorporate all available information,
        and it is impossible to exceed market returns by trading actively.
        </Typography>
        <br />
        <Typography sx={descStyle}><img src={logo_simple} style={{width: 25, verticalAlign: "middle"}} />
        {" "}Event Studies have been used as both evidence
        in favour of the EMH, as well as to find empirical counter-evidence to the theoretical premise behind the view
        on efficiency.
        </Typography>

        </Box>
      </Grid>
      <Grid item xs={12} lg={3}>
        <Box mr={15} ml={{lg: 0, xs: 15}} mt={{lg: 15, xs: 5}}  display={{lg: "block", xs: "none"}}>
        <img src={stonks} style={{width: "100%"}}/>
        </Box>
      </Grid>

      <Grid item xs={12} lg={3}>
        <br /><br /><br />
      </Grid>

    </Grid>
);
}

export default EventStudy;
