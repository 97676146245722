import '../App.css';

import React, { useEffect, useState } from 'react';
import {Card, Typography, Button, Divider, Grid, TextField, Select, MenuItem} from "@mui/material";
import { useNavigate } from "react-router-dom";

import {globalData} from '../Global.js';

import {color_palette, font_palette} from '../style/StylePalette.js';

import Signup from '../components/Signup.js';
import Signin from '../components/Signin.js';

import hero from '../static/hero.jpg';

function  AccountStatus  (props) {
 const headerStyle = {
    fontFamily: font_palette["header"],
    color: color_palette["black"],
    fontSize: 36,
    fontWeight: "bolder",
 }
 const descStyle = {
    fontFamily: font_palette["header"],
    color: color_palette["black"],
    fontSize: 16,
 }

    const [accountLevel, setAccountLevel] = useState(0);
    const [accountLevelDesc, setAccountLevelDesc] = useState("UNKNOWN");

    useEffect(() => {
        fetch(`${globalData.api_root}/user/status`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem("token")
                    },
                    crossDomain: true,
                  }).then(response=>{
                        if (!response.ok) {
                            throw new Error(`failed (${response.status})`)
                        }
                        return response.json()
                    }).then(json_data=> {
                        setAccountLevel(json_data["code"]);
                        setAccountLevelDesc(json_data["code_desc"]);
                    }).catch(err=>{
                        console.log("found error:");
                        console.log(err);
                    });
    },[]);
  return (<>
      <Grid item xs={3} mt={6} />
      <Grid item xs={6} mt={6}>
        <Typography style={headerStyle}>Your Account Info</Typography>

            <Typography sx={descStyle} mt={3}>
              Your account level is currently <Typography display="inline" fontWeight="bold">{accountLevelDesc}</Typography>
            </Typography>

            {accountLevel == 1 && (
                <Typography sx={descStyle} mt={3}>
                  This means you have access to basic anagami.ai features. It does not mean you have access to our complete
                    database of indicators, tickers, and subscriptions <br /><br />We recommend you update to premium to make the
                    most of what anagami.ai has to offer
                </Typography>
            )}
            {accountLevel == 2 && (
                <Typography sx={descStyle} mt={3}>
                  This means you have access to the full suite of anagami.ai features
                </Typography>
            )}
      </Grid>
      <Grid item xs={3} mt={6} />
</>);
}

export default AccountStatus;
