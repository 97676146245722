import '../App.css';

import React, { useEffect, useState } from 'react';
import {Card, Typography, Button, Divider, Grid, TextField, Select, MenuItem, Switch, Tooltip} from "@mui/material";
import { useNavigate } from "react-router-dom";

import {globalData} from '../Global.js';



import dayjs from 'dayjs';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {color_palette, font_palette} from '../style/StylePalette.js';

function  MoveQueryOptions  (props) {
    const labelStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["gray"],
        fontSize: 16,
     }
    const buttonStyle = {
        fontFamily: font_palette["text"],
        color: color_palette["white"],
        backgroundColor: color_palette["highlight"],
        fontSize: 12,
        width: "100%",
    }
    const buttonStyleHighlight = {
        fontFamily: font_palette["text"],
        color: color_palette["black"],
        border: "1px solid " + color_palette["primary"],
        fontSize: 12,
        width: "100%",
    }
    const bodyStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["black"],
        fontSize: 16,
     }

  const [minMove, setMinMove] = React.useState(0.03);
  const [maxMovePctMin, setMaxMovePctMin] = React.useState(2.0);
  const [minTimePctMax, setMinTimePctMax] = React.useState(0);
  const [maxTime, setMaxTime] = React.useState(1.0);
  const [longOrShort, setLongOrShort] = React.useState(props.defaultLongOrShort);
  const [minsOrMaxes, setMinsOrMaxes] = React.useState(true);
  const [noOverlapTime, setNoOverlapTime] = React.useState(5.0);


  useEffect(() => {
    props.setExtraParams({
        "move_size_min":                Number(minMove),
        "move_size_max_ratio_of_min":   Number(maxMovePctMin),
        "move_time_max":                Number(maxTime)*1440,
        "move_time_min_ratio_of_max":   Number(minTimePctMax),
        "trade_same_dir_as_extr_or_no": (longOrShort ^ minsOrMaxes) ? -1 : 1,
        "long_or_short":                longOrShort ? 1 : -1,
        "mins_or_maxes":                minsOrMaxes ? 1 : -1,
        "no_overlap_time":              Number(noOverlapTime),
    })
  }, [minMove, maxMovePctMin, minTimePctMax, maxTime, minsOrMaxes, noOverlapTime, longOrShort]);

  useEffect(() => {
    props.setExtraParams({
        "move_size_min":                Number(minMove),
        "move_size_max_ratio_of_min":   Number(maxMovePctMin),
        "move_time_max":                Number(maxTime)*1440,
        "move_time_min_ratio_of_max":   Number(minTimePctMax),
        "trade_same_dir_as_extr_or_no": (longOrShort ^ minsOrMaxes) ? -1 : 1,
        "long_or_short":                longOrShort ? 1 : -1,
        "mins_or_maxes":                minsOrMaxes ? 1 : -1,
        "no_overlap_time":              Number(noOverlapTime)*1440, // send days->minutes when calling the backend
    })
  }, []);


  const setDefaults = (json_data) => {
    var eventType = "move";

    setMinMove(json_data[eventType]["move_size_min"]);
    setMaxMovePctMin(json_data[eventType]["move_size_max_ratio_of_min"]);
    setMaxTime(json_data[eventType]["move_time_max"]/1440); // api handles move time in minutes, but the frontend uses days
    setMinTimePctMax(json_data[eventType]["move_time_min_ratio_of_max"]);
    setLongOrShort((json_data[eventType]["trade_same_dir_as_extr_or_no"]*json_data[eventType]["mins_or_maxes"])==1)
    setMinsOrMaxes(json_data[eventType]["mins_or_maxes"] == 1);
    setNoOverlapTime(json_data[eventType]["no_overlap_time"]/1440); // no overlap time: minutes->days
  }
    useEffect(() => {
        fetch(`${globalData.api_root}/query/defaults`, {crossDomain: true}).then(response=>response.json()).then(json_data=> {
            setDefaults(json_data);
        }).catch(err=>{
            console.log("found error:");
            console.log(err);
        })
     }, []);

  return (
      <>
          <Grid item lg={3} sm={6} xs={12}>
            <Tooltip title={props.varDescriptions["mins_or_maxes"]} style={{textAlign: "left"}}>
            <Typography style={labelStyle}>
              Moves Up or Down?
            </Typography>
            </Tooltip>
            <Select
                value={minsOrMaxes}
                style={{width: '100%'}}
                onChange={(e)=>{setMinsOrMaxes(e.target.value)}}>
                <MenuItem value={true}>Up</MenuItem>
                <MenuItem value={false}>Down</MenuItem>
            </Select>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <Tooltip title={props.varDescriptions["trade_same_dir_as_extr_or_no"]} style={{textAlign: "left"}}>
            <Typography style={labelStyle}>
              Long or Short?
            </Typography>
            </Tooltip>
            <Select
                value={longOrShort}
                style={{width: '100%'}}
                id='long-or-short'
                onChange={(e)=>{setLongOrShort(e.target.value)}}>
                <MenuItem value={true}>Long</MenuItem>
                <MenuItem value={false}>Short</MenuItem>
            </Select>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <Tooltip title={props.varDescriptions["no_overlap_time"]} style={{textAlign: "left"}}>
            <Typography style={labelStyle}>
              No Overlap Time (in Days)
            </Typography>
            </Tooltip>
            <TextField
            value={noOverlapTime}
            style={{width: '100%'}}
            onChange={(e)=>{setNoOverlapTime(e.target.value)}}>
            </TextField>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <Tooltip title={props.varDescriptions["move_size_min"]} style={{textAlign: "left"}}>
            <Typography style={labelStyle}>
              Minimum Move Size
            </Typography>
            </Tooltip>
            <TextField value={minMove}
            style={{width: '100%'}}
            onChange={(e)=>{setMinMove(e.target.value)}}>
            </TextField>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <Tooltip title={props.varDescriptions["move_size_max_ratio_of_min"]} style={{textAlign: "left"}}>
            <Typography style={labelStyle}>
              Maximum Move Size (Ratio of Minimum)
            </Typography>
            </Tooltip>
            <TextField value={maxMovePctMin}
            style={{width: '100%'}}
            onChange={(e)=>{setMaxMovePctMin(e.target.value)}}>
            </TextField>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <Tooltip title={props.varDescriptions["move_time_max"]} style={{textAlign: "left"}}>
            <Typography style={labelStyle}>
              Maximum Move Time (Days)
            </Typography>
            </Tooltip>
            <TextField value={maxTime}
            style={{width: '100%'}}
            onChange={(e)=>{setMaxTime(e.target.value)}}>
            </TextField>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <Tooltip title={props.varDescriptions["move_time_min_ratio_of_max"]} style={{textAlign: "left"}}>
            <Typography style={labelStyle}>
              Minimum Move Time (Ratio of Maximum)
            </Typography>
            </Tooltip>
            <TextField
            value={minTimePctMax}
            style={{width: '100%'}}
            onChange={(e)=>{setMinTimePctMax(e.target.value)}}>
            </TextField>
          </Grid>
      </>
    );
}

MoveQueryOptions.defaultProps = {
    defaultLongOrShort: true,
  }

export default MoveQueryOptions;
