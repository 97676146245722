import '../App.css';

import React from 'react';
import {Box, Typography} from "@mui/material";

import {color_palette, font_palette} from '../style/StylePalette.js';

import Signup from '../components/Signup.js';
import Signin from '../components/Signin.js';

import { useNavigate, Link } from "react-router-dom";

import hero from '../static/hero.jpg';
import QueryFailed from '../components/QueryFailed.js';

function  QueryFailedPage  (props) {

  return (<>
        <QueryFailed />
</>);
}

export default QueryFailedPage;
