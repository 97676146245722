import '../App.css';

import React, { useEffect, useState } from 'react';
import {Card, Typography, Button, Divider, Grid, TextField, Select, MenuItem} from "@mui/material";
import { useNavigate } from "react-router-dom";

import {globalData} from '../Global.js';


import ReactGA from 'react-ga4';



import dayjs from 'dayjs';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {color_palette, font_palette} from '../style/StylePalette.js';

function  Signin  (props) {
    const labelStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["gray"],
        fontSize: 16,
     }
    const buttonStyle = {
        fontFamily: font_palette["text"],
        color: color_palette["white"],
        backgroundColor: color_palette["highlight"],
        fontSize: 12,
        width: "100%",
        maxWidth: "20%",
    }

    const bodyStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["black"],
        fontSize: 16,
     }

    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [signinStatus, setSigninStatus] = useState("");

    const signIn = () => {
        ReactGA.event({
            category: 'User',
            action: 'Sign In',
        });
        setSigninStatus("Signing in...");
        fetch(`${globalData.api_root}/token`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    crossDomain: true,
                    body: JSON.stringify({
                            username: email,
                            password: password
                    })}).then(response=>{
                        if (response.status >= 300) {
                            if (response.status === 401) {
                                setSigninStatus("Incorrect username or password, please try again...");
                                throw new Error(`failed (${response.status})`);
                            } else {
                                setSigninStatus("Sign in failed, please try again...");
                                throw new Error(`failed (${response.status})`);
                            }
                        }
                        return response.json();
                    }).then(json_data=> {
                        localStorage.setItem("token", json_data["access_token"]);
                        window.dispatchEvent(new Event("storage"));
                        navigate("/query");
                    }).catch(err=>{
                        ReactGA.event({
                            category: 'User',
                            action: 'Sign In Error',
                        });
                        console.log("found error:");
                        console.log(err);
                    });
    }

  return (
      <>
      <Grid item xs={12}>
            <Typography sx={{color: "red"}}>
                {signinStatus}
            </Typography>
            <Typography sx={labelStyle}>
              Email*
            </Typography>
            <TextField value={email} sx={{maxWidth: {md: "20%", xs: "80%"}, width: "100%"}} onKeyDown={(e)=>{if (e.key==="Enter"){signIn()}}} onChange={(e) => setEmail(e.target.value)}>
            </TextField>

        <Grid item xs={12} mt={2}>
            <Typography sx={labelStyle}>
              Password*
            </Typography>
            <TextField type="password" sx={{maxWidth: {md: "20%", xs: "80%"}, width: "100%"}} onKeyDown={(e)=>{if (e.key==="Enter"){signIn()}}} value={password} onChange={(e) => setPassword(e.target.value)}>
            </TextField>
      </Grid>
      <Grid item xs={12} mt={6}>
        <Button  style={buttonStyle} onClick={signIn}>
          Sign In
        </Button>
      </Grid>

      </Grid>
      </>
    );
}

export default Signin;