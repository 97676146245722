import '../App.css';

import TimeSeriesChart from '../components/TimeSeriesChart.js';
import SummaryTable from '../components/SummaryTable.js';
import DriftChart from '../components/DriftChart.js';
import React, { useEffect, useState, useRef } from 'react';
import {Card, Typography, Button, Divider, Grid} from "@mui/material";

import {ArrowDropDown} from '@mui/icons-material';


import {color_palette, font_palette} from '../style/StylePalette.js';


import {globalData} from '../Global.js';

import axios from 'axios';

function  QueryResults  (props) {
    const takeawayConclusionStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["black"],
        fontSize: 24,
        fontWeight: "bold",
        textAlign: "left",
    }


    const headerStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["gray"],
        fontSize: 24,
        fontWeight: "bold",
        textAlign: "left"
    }

    const mainHeaderStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["white"],
        fontSize: 36,
        fontWeight: "bold",
        textAlign: "left"
    }
    const descStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["white"],
        fontSize: 16,
        textAlign: "left"
    }



    const topStyle = {
        backgroundColor: color_palette["black"],
        textAlign: "left",
    }

    const buttonStyleHighlight = {
        fontFamily: font_palette["text"],
        color: color_palette["black"],
        border: "1px solid " + color_palette["primary"],
        fontSize: 12,
        width: 300,
    }

    const [ts_data, setTsData] = React.useState({
        datasets: [{
            label: "no data",
            data: []
        }]
    });

    const [histPnlData, setHistPnlData] = React.useState({
        datasets: [{
            label: "no data",
            data: []
        }]
    });

    const [returnData, setReturnData] = React.useState({
        datasets: [{
            label: "no data",
            data: []
        }]
    });


    const vizColors = ["#dc3545", "#0d6efd", "#0dcaf0"];


    const [refHook, setRefHook] = React.useState(0);
    const [summaryData, setSummaryData] = React.useState({});
    const [driftDetailData, setDriftDetailData] = React.useState({});
    const [driftDetailHeader, setDriftDetailHeader] = React.useState({});
    const [summaryHeader, setSummaryHeader] = React.useState([]);
    const [returnDataTabular, setReturnDataTabular] = React.useState({});
    const [returnDataHeader, setReturnDataHeader] = React.useState([]);
    const [takeaways, setTakeaways] = React.useState([]);
    const [conclusion, setConclusion] = React.useState("");

    const [figureTitles, setFigureTitles] = React.useState({})

    const [displayResults, setDisplayResults] = React.useState(false);

    const concRef = useRef(null);

    const updateReturnData = (return_json) => {
        /* use summary data (in json format) to populate historical pnl chart */
        const NORMAL_RETURN_IDX = 0;
        const POSTEVENT_RETURN_IDX = 1;
        const ABNORMAL_RETURN_IDX = 2;
        const abnormal_scale = 0.05;
        var all_datasets = [];
        all_datasets[NORMAL_RETURN_IDX] = {
                    label: `normal drift`,
                    backgroundColor: vizColors[NORMAL_RETURN_IDX % vizColors.length],
                    borderColor: vizColors[NORMAL_RETURN_IDX % vizColors.length],
                    borderWidth: 2,
                    pointRadius: 0,
                    fill: true,
                    spanGaps: true,
                    showLine: true,
                    data: [],
                    type: 'line',
                };
        all_datasets[POSTEVENT_RETURN_IDX] = {
                    label: `post-event drift`,
                    backgroundColor: vizColors[POSTEVENT_RETURN_IDX % vizColors.length],
                    borderColor: vizColors[POSTEVENT_RETURN_IDX % vizColors.length],
                    borderWidth: 2,
                    pointRadius: 0,
                    fill: true,
                    spanGaps: true,
                    showLine: true,
                    data: [],
                    type: 'line',
                };
        all_datasets[ABNORMAL_RETURN_IDX] = {
                    label: `days with abnormal returns (circled)`,
                    backgroundColor: vizColors[POSTEVENT_RETURN_IDX % vizColors.length],
                    borderColor: vizColors[POSTEVENT_RETURN_IDX % vizColors.length],
                    borderWidth: 2,
                    backgroundColor: "white",
                    spanGaps: false,
                    showLine: false,
                    pointRadius: 5,
                    data: [],
                    type: 'line',
                };

        for (var i = 0; i < return_json.length; i++) {
            all_datasets[NORMAL_RETURN_IDX]["data"].push({
                x: return_json[i]["t"],
                y: return_json[i]["normal return %"],
            })
            all_datasets[POSTEVENT_RETURN_IDX]["data"].push({
                x: return_json[i]["t"],
                y: return_json[i]["avg post-event % drift"],
            })
            if (return_json[i]["statistically abnormal behavior"] == "YES") {
                all_datasets[ABNORMAL_RETURN_IDX]["data"].push({
                    x: return_json[i]["t"],
                    y: return_json[i]["avg post-event % drift"],
                })
            } else {
                console.log(`didnt find abnormal drifts: ${return_json[i]["statistically abnormal behavior"]}`)
            }
        }

        setReturnData({
            datasets: all_datasets
            });
    }

    const setPnlData = (summary_json) => {
        /* use summary data (in json format) to populate historical pnl chart */
        var dataset = [{
                    label: `NAV over time`,
                    backgroundColor: vizColors[0],
                    borderColor: vizColors[0],
                    borderWidth: 2,
                    pointRadius: 0,
                    fill: true,
                    spanGaps: true,
                    showLine: true,
                    data: [],
                }];

        for (var i = 0; i < summary_json["ticker"].length; i++) {
            var tk = summary_json["ticker"][i];

            if (tk === "NA") continue;

            dataset[0]["data"].push({
                x: new Date(summary_json["date"][i]),
                y: summary_json["NAV"][i]
            })
        }

        setHistPnlData({
            datasets: dataset
            });
    }

    const plotHistoryAndEvents = (time_series,events) => {
        const tickers = Object.keys(time_series);

        var all_datasets = [];

        for (var i = 0; i < tickers.length; i++) {
            var tk = tickers[i];
            all_datasets.push ({
                label: `${tk} events`,
                backgroundColor: "white",
                borderColor: vizColors[i % vizColors.length],
                borderWidth: 2,
                pointRadius: 5,
                showLine: false,
                data: events.filter(e=>e.ticker === tk).map(e => {
                     var dt = new Date(e.date);

                     return {x: dt,
                        y: e.price};
                }),
                hidden: i > 0, // hide if it's not the first ticker..
             });
        }

        for (var i = 0; i < tickers.length; i++) {
            var tk = tickers[i];
            all_datasets.push ({
                label: `${tk}`,
                backgroundColor: vizColors[i % vizColors.length],
                borderColor: vizColors[i % vizColors.length],
                borderWidth: 2,
                pointRadius: 0,
                fill: true,
                spanGaps: true,
                showLine: true,
                data: time_series[tk].map(e => {
                     var dt = new Date(e.date);
                     return {x: dt,
                        y: e.close};
                }),
                hidden: i > 0, // hide if it's not the first ticker..
             });
        }

        setTsData({
            datasets: all_datasets
            });
        setRefHook(refHook+1);
    }

    const updateTabularData = (json_input, header_col, headerHook, dataHook, headerLimit=-1, validRows="none") => {
        if (validRows !== "none") {
            console.log(`tab: json input: ${JSON.stringify(json_input)}`);
        }

        var return_data_header = JSON.parse(JSON.stringify(json_input.map((x)=>x[header_col].toString())));
        if (headerLimit != -1) {
            return_data_header = return_data_header.slice(0,headerLimit);
        }
        //json_input.map((x)=>{delete x[header_col]});

        headerHook(return_data_header);
        var new_return_data = {};
        var rows = Object.keys(json_input[0]);
        if (validRows !== "none") {
            rows = rows.filter((x)=>(validRows.includes(x)));
        } else {
            rows = rows.filter((x)=>(x != "t"));

        }
        for (var i=0; i < json_input.length; i++) {
            new_return_data[json_input[i][header_col]] = rows.map((x)=>(json_input[i][x]));
        }

        dataHook(new_return_data);

        if (validRows !== "none") {
            console.log(`tab: header: ${JSON.stringify(return_data_header)}`);
            console.log(`tab: data: ${JSON.stringify(new_return_data)}`);
        }
    }

    useEffect(() => {
        fetch(`${globalData.api_root}/query/fetch/${props.queryId}`,
                {crossDomain: true,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem("token")
                    },
                }
        ).then(response=>{
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(json_data=> {
            if (json_data["status"] === "failed") {
                throw Error("failed query");
            }
            setTakeaways(json_data["data"]["takeaways"]);
            setConclusion(json_data["data"]["conclusion"]);
            setFigureTitles(json_data["data"]["titles"]);

            plotHistoryAndEvents(json_data["data"]["time_series"], json_data["data"]["events"])
            setSummaryData(json_data["data"]["summary"]);


            setSummaryHeader(json_data["data"]["summary_header"]);
            setPnlData(json_data["data"]["summary"]);
            updateReturnData(json_data["data"]["returns"]);


            updateTabularData(json_data["data"]["returns"], "t", setReturnDataHeader, setReturnDataTabular, 18,
                    ["avg post-event % drift", "% of positive post-event drifts", "statistically abnormal behavior"]);
            updateTabularData(json_data["data"]["drift_detail"], "t", setDriftDetailHeader, setDriftDetailData, 17);

            console.log(`takeaways: ${json_data["data"]["takeaways"]}`);
            setDisplayResults(true);
        }).catch(err=>{
            console.log("found error in query fetch:");
            console.log(err);
            props.setQueryStatus("failed");
        })}, []);

  return (<>
  {displayResults && (
    <Grid container>
      <Grid item xs={12}>
        <div style={{}}>
        <Button onClick={()=>{concRef.current?.scrollIntoView({behavior: 'smooth'});}} style={buttonStyleHighlight}>Go to conclusion   <ArrowDropDown /></Button>
        </div>
      </Grid>
        <Grid item xs={1} />
        <Grid item mt={3} xs={10}>
          <div style={{border: "1px solid " + color_palette["primary"], borderRadius: 8, width: "100%"}}>
          <Typography ml={5} mr={5} mt={1} mb={1} sx={takeawayConclusionStyle}>{takeaways[0]}</Typography>
          </div>
          <br />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Typography mt={6} sx={headerStyle}>Figure 1: {figureTitles[1]}</Typography>
                <Divider sx={{backgroundColor: color_palette["gray"]}} />
        <div style={{width: "100%", height: 400, display:"flex"}}>
        <DriftChart ticker={refHook} data={returnData} />
        </div>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={1} />
        <Grid item xs={10} mt={3}>
          <Divider sx={{backgroundColor: color_palette["gray"]}} />
            <div style={{width: "100%"}}>
            <SummaryTable data={returnDataTabular} header={returnDataHeader} ptg morePtgRows={[0]} precision={0} direction="colsfirst" />
            </div>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Typography mt={6} sx={headerStyle}>Figure 2: {figureTitles[0]}</Typography>
                <Divider sx={{backgroundColor: color_palette["gray"]}} />
        <div style={{width: "100%", height: 400, display:"flex"}}>
        <TimeSeriesChart ticker={refHook} data={ts_data} beginAtZero yLabel={"Price"} />
        </div>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={1} />
        <Grid item xs={10}>
            <br /><br />
            <Divider sx={{backgroundColor: color_palette["gray"]}} />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={1} />
        <Grid item xs={10} mt={3}>
              <div style={{border: "1px solid " + color_palette["primary"], borderRadius: 8, width: "100%"}}>
                <Typography ml={5} mr={5} mt={1} mb={1} sx={takeawayConclusionStyle}>{takeaways[1]}</Typography>
              </div>
        </Grid>
        <Grid item xs={1} />

        <Grid item xs={1} />
        <Grid item xs={10}>
          <Typography mt={6} sx={headerStyle}>Figure 3: {figureTitles[4]}</Typography>
                <Divider sx={{backgroundColor: color_palette["gray"]}} />
        <div style={{width: "100%"}}>
        <SummaryTable data={summaryData} header={summaryHeader} ptg direction="colsfirst" />
        </div>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Typography mt={6} sx={headerStyle}>Figure 4: {figureTitles[5]}</Typography>
                <Divider sx={{backgroundColor: color_palette["gray"]}} />
        <div style={{width: "100%", height: 400, display:"flex"}}>
        <TimeSeriesChart ticker={refHook} data={histPnlData} ptg yLabel={"NAV"} />
        </div>
        </Grid>
        <Grid item xs={1} />

        <Grid item xs={1} />
        <Grid item xs={10}>
          <Typography mt={6} sx={headerStyle}>Figure 5: {figureTitles[3]}</Typography>
                <Divider sx={{backgroundColor: color_palette["gray"]}} />
        <div style={{width: "100%"}}>
        <SummaryTable data={driftDetailData} header={driftDetailHeader} ptg precision={1} nonPtg={["event price", "event direction", "outlier?"]} morePtg={[]} direction="colsfirst" />
        </div>
        </Grid>
        <Grid item xs={1} />

        <Grid item xs={1} />
        <Grid item xs={10}>
            <br /><br />
            <Divider sx={{backgroundColor: color_palette["gray"]}} />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={1} />
        <Grid item xs={10} mt={3} mb={3}>
          <br />
          <div ref={concRef}>
          <div style={{border: "1px solid " + color_palette["primary"], borderRadius: 8, width: "100%"}}>
            <Typography ml={5} mr={5} sx={takeawayConclusionStyle}>{conclusion}</Typography>
          </div>
          </div>
          <br />
          <br />
        </Grid>
        <Grid item xs={1} />
    </Grid>)}
   </>);
}

export default QueryResults;