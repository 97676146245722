import '../App.css';

import React, { useEffect, useState } from 'react';
import {Card, Typography, Button, Divider, Grid, TextField, Select, MenuItem, Switch, Tooltip} from "@mui/material";

import { useNavigate } from "react-router-dom";

import {globalData} from '../Global.js';



import dayjs from 'dayjs';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {color_palette, font_palette} from '../style/StylePalette.js';

function  FundamentalQueryOptions  (props) {
    const labelStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["gray"],
        fontSize: 16,
     }
    const buttonStyle = {
        fontFamily: font_palette["text"],
        color: color_palette["white"],
        backgroundColor: color_palette["highlight"],
        fontSize: 12,
        width: "100%",
    }
    const buttonStyleHighlight = {
        fontFamily: font_palette["text"],
        color: color_palette["black"],
        border: "1px solid " + color_palette["primary"],
        fontSize: 12,
        width: "100%",
    }
    const bodyStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["black"],
        fontSize: 16,
     }

  const [longOrShort, setLongOrShort] = React.useState(props.defaultLongOrShort);


  useEffect(() => {
    props.setExtraParams({
        "trade_same_dir_as_extr_or_no":   longOrShort ? 1 : -1,
        "long_or_short":                longOrShort ? 1 : -1,
    })
  }, [longOrShort]);

  useEffect(() => {
    props.setExtraParams({
        "trade_same_dir_as_extr_or_no":   longOrShort ? 1 : -1,
        "long_or_short":                longOrShort ? 1 : -1,
    })
  }, []);

  const setDefaults = (json_data) => {
    var eventType = "move";

    setLongOrShort(json_data[eventType]["trade_same_dir_as_extr_or_no"] == 1);
  }
    useEffect(() => {
        /*fetch(`${globalData.api_root}/query/defaults`, {crossDomain: true}).then(response=>response.json()).then(json_data=> {
            setDefaults(json_data);
            setLongOrShort(props.defaultLongOrShort);
        }).catch(err=>{
            console.log("found error:");
            console.log(err);
        })*/
     }, []);


  return (
      <>
          <Grid item lg={3} sm={6} xs={12}>
            <Tooltip title={"to trade in same or opposite direction as the event"} style={{textAlign: "left"}}>
            <Typography style={labelStyle}>
              Long or Short?
            </Typography>
            </Tooltip>
            <Select
                value={longOrShort}
                style={{width: '100%'}}
                id='long-or-short'

                onChange={(e)=>{setLongOrShort(e.target.value)}}>
                <MenuItem value={true}>Long</MenuItem>
                <MenuItem value={false}>Short</MenuItem>
            </Select>
          </Grid>
      </>
    );
}

FundamentalQueryOptions.defaultProps = {
    defaultLongOrShort: true,
  }

export default FundamentalQueryOptions;