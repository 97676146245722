import '../App.css';

import React, { useEffect, useState } from 'react';
import {Card, Typography, Button, Divider, Grid} from "@mui/material";

import {color_palette, font_palette} from '../style/StylePalette.js';


import {globalData} from '../Global.js';

import QueryInput from '../components/QueryInput.js';
import QueryResults from '../components/QueryResults.js';
import QueryLoading from '../components/QueryLoading.js';
import QueryFailed from '../components/QueryFailed.js';

import axios from 'axios';

function  InterestRatePage  (props){
    const headerStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["white"],
        fontSize: 36,
        fontWeight: "bold"
    }
    const mainDescStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["white"],
        fontSize: 16,
        textAlign: "left"
    }
    const descStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["black"],
        fontSize: 36,
    }

    const topStyle = {
        backgroundColor: color_palette["black"],
        textAlign: "left",
    }

    const bodyStyle = {
        backgroundColor: color_palette["white"],
        textAlign: "left",
    }


  const [queryId, setQueryId] = React.useState("");
  const [queryStatus, setQueryStatus] = React.useState("unsubmitted");


    useEffect(() => {
        if (props.queryId !== -1) {
            setQueryId(props.queryId);
            setQueryStatus("fulfilled");
        }
    }, [])

  return (<>
    <Grid container>
      <Grid item xs={12}>
        <div style={topStyle}>
           <br />
            <Divider style={{backgroundColor: color_palette["gray"]}} />
            <Typography style={headerStyle} ml={{lg:20,sm:5}} mr={{lg:20,sm:5}}>
              Analyse Event Impact
            </Typography>
            <Typography sx={mainDescStyle} ml={{lg:20,sm:5}} mr={{lg:20,sm:5}}>
              Learn how events such as Fed interest decisions, inflation, moves in VIX index, or employment data affect stocks and cryptocurrencies.
            </Typography>
            <br />
         </div>
      </Grid>
      <Grid item xs={12} mt={5} />
      <Grid item xs={1} />
      <Grid item xs={10}>
        <div style={bodyStyle}>
            <QueryInput
                setQueryId={setQueryId}
                setQueryStatus={setQueryStatus}
                defaultEvent={"Fed hikes interest rates"}
                defaultEventDesc={"Fed hikes interest rates"}
                defaultLongOrShort={true} />
         </div>
      </Grid>
      <Grid item xs={1}>
      </Grid>
    </Grid>
    <br /><br />
    <Divider style={{backgroundColor: color_palette["gray"]}} mt={10}/>
    <br />
    {queryStatus === "fulfilled" &&
    <QueryResults queryId={queryId} />}
    {queryStatus === "waiting" &&
    <QueryLoading queryStatus={queryStatus} setQueryStatus={setQueryStatus} queryId={queryId} />}
    {queryStatus === "failed" &&
    <QueryFailed />}
   </>);
}

InterestRatePage.defaultProps = {
    queryId: -1,
  }
export default InterestRatePage;
