import '../App.css';

import React, { useEffect, useState, useContext, useRef } from 'react';
import {Card, Typography, Button, Divider, Grid, TextField, Select, MenuItem,Tooltip,Box, Popper} from "@mui/material";
import { useNavigate } from "react-router-dom";


import { globalData, contactData } from '../Global.js';
import MoveQueryOptions from './MoveQueryOptions.js';
import FundamentalQueryOptions from './FundamentalQueryOptions.js';

import ReactGA from 'react-ga4';

import dayjs from 'dayjs';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {color_palette, font_palette} from '../style/StylePalette.js';

import Joyride from 'react-joyride';

function  QueryInput  (props) {
    const labelStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["gray"],
        fontSize: 16,
     }
    const labelStyleHighlight = {
        fontFamily: font_palette["header"],
        color: color_palette["primary"],
        fontSize: 16,
        cursor: "pointer",
     }
    const buttonStyle = {
        fontFamily: font_palette["text"],
        color: color_palette["white"],
        backgroundColor: color_palette["highlight"],
        fontSize: 12,
        width: "100%",
        "&:hover": {
            backgroundColor: color_palette["white"],
            color: color_palette["black"],
            border: `1px solid ${color_palette["primary"]}`
        },
    }
    const buttonStyleHighlight = {
        fontFamily: font_palette["text"],
        color: color_palette["black"],
        border: "1px solid " + color_palette["primary"],
        fontSize: 12,
        width: "100%",
    }
    const bodyStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["black"],
        fontSize: 16,
        whiteSpace: "pre-line",
     }

  const navigate = useNavigate();

  const [startDate, setStartDate] = React.useState(dayjs('2017-12-31'));
  const [endDate, setEndDate] = React.useState(dayjs());

  const tkr_to_use = props.defaultTicker;
  const evt_to_use = props.defaultEvent;
  const [tickers, setTickers] = React.useState([{tkr_to_use: props.defaultTicker}]);
  const [eventNames, setEventNames] = React.useState({evt_to_use: props.defaultEventDesc});
  const [selectedTicker, setSelectedTicker] = React.useState(props.defaultTicker);
  const [prevTicker, setPrevTicker] = React.useState(props.defaultTicker);
  const [selectedEvent, setSelectedEvent] = React.useState(props.defaultEvent);
  const [extraParams, setExtraParams] = React.useState({});
  const [querySummary, setQuerySummary] = React.useState("");
  const [varDescriptions, setVarDescriptions] = React.useState({});
  const [multiTickerEvents, setMultiTickerEvents] = React.useState([]);
  const [nonFundamentalEvents, setNonFundamentalEvents] = React.useState(["Cramer's tweets"]);
  const [enabledEvents, setEnabledEvents] = React.useState([props.defaultEvent]);
  const [enabledTickers, setEnabledTickers] = React.useState([props.defaultTicker]);

  const setDefaults = (json_data) => {
    var eventType = "fundamental";
    if (selectedEvent == "MOVE") {
        eventType = "move";
    }

    if (json_data[eventType]["from_date"] != 0) {
        console.log(`start date: ${json_data[eventType]["from_date"]}`);
        //setStartDate(dayjs(json_data[eventType]["from_date"]));
    }

    if (json_data[eventType]["to_date"] != 0) {
        //setEndDate(dayjs(json_data[eventType]["to_date"]));
    }
  }

  useEffect(() => {
        fetch(`${globalData.api_root}/event/non_fundamental`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            },
            crossDomain: true,
            }).then((response)=>{
                if (response.status == 401) {
                    localStorage.removeItem("token");
                    navigate("/signin");
                }
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            }).then(response=>response.json()).then(json_data=> {
                setNonFundamentalEvents(json_data["result"])
            }).catch(err=>{
                props.setQueryStatus("failed")
                console.log("found error in query call:");
                console.log(err);
                });
        fetch(`${globalData.api_root}/event/multiticker`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            },
            crossDomain: true,
            }).then((response)=>{
                if (response.status == 401) {
                    localStorage.removeItem("token");
                    navigate("/signin");
                }
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            }).then(response=>response.json()).then(json_data=> {
                setMultiTickerEvents(json_data["result"])
            }).catch(err=>{
                props.setQueryStatus("failed")
                console.log("found error in query call:");
                console.log(err);
                });
        fetch(`${globalData.api_root}/ticker`, {crossDomain: true}).then(response=>response.json()).then(json_data=> {
                setTickers(json_data);
            }).catch(err=>{
                console.log("found error in ticker call:");
                console.log(err);
            });
        fetch(`${globalData.api_root}/event_names`, {crossDomain: true}).then(response=>response.json()).then(json_data=> {
                setEventNames(json_data);
                console.log(`event names: ${JSON.stringify(json_data)}`);
            }).catch(err=>{
                console.log("found error in event names call:");
                console.log(err);
            });
        fetch(`${globalData.api_root}/query/available_features`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                },
                mode: 'cors',
                crossDomain: true,
            }).then((response)=>{
                    if (response.status == 401) {
                        localStorage.removeItem("token");
                        navigate("/signin");
                    }
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response;
                }).then(
                    response=>response.json()
                ).then(json_data=> {
                    setEnabledEvents(json_data["events"]);
                    setEnabledTickers(json_data["tickers"]);
                    console.log("set enabled events & enabled tickers")
                    console.log(`to: ${JSON.stringify(json_data)}`);
                    console.log(`to: ${JSON.stringify(enabledEvents)}`);
                    console.log(`to: ${JSON.stringify(enabledTickers)}`);
                }).catch(err=>{
                    console.log("found error in event names call:");
                    console.log(err);
                });
  }, []);

    useEffect(() => {
        fetch(`${globalData.api_root}/query/defaults`, {crossDomain: true}).then(response=>response.json()).then(json_data=> {
            setDefaults(json_data);
        }).catch(err=>{
            console.log("found error:");
            console.log(err);
        })
     }, [selectedEvent]);

    useEffect(() => {
        fetch(`${globalData.api_root}/query/desc`, {crossDomain: true}).then(response=>response.json()).then(json_data=> {
            setVarDescriptions(json_data);
        }).catch(err=>{
            console.log("found error:");
            console.log(err);
        })
     }, [selectedEvent]);

    useEffect(() => {
        fetch(`${globalData.api_root}/query/getsummary`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    crossDomain: true,
                    body: JSON.stringify({
                        query: {...{
                            ticker: selectedTicker,
                            event_id: selectedEvent,
                            from_date: startDate.format("DD-MM-YYYY"),
                            to_date: endDate.format("DD-MM-YYYY"),
                        }, ...extraParams}
                    })}).then(response=>response.json()).then(json_data=> {
                        setQuerySummary(json_data["response"]);
                    }).catch(err=>{
                        console.log("found error:");
                        console.log(err);
                        });
        }, [selectedTicker,selectedEvent,startDate,endDate,extraParams]);

    const runQuery = () => {
        var queryDictionary = {...{
                            ticker: selectedTicker,
                            event_id: selectedEvent,
                            from_date: startDate.format("DD-MM-YYYY"),
                            to_date: endDate.format("DD-MM-YYYY"),
                        }, ...extraParams}
        ReactGA.event({
            category: 'Query',
            action: 'Query submitted',
            label: JSON.stringify(queryDictionary)
        });
        fetch(`${globalData.api_root}/query/start`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem("token")
                    },
                    crossDomain: true,
                    body: JSON.stringify({
                        query: queryDictionary
                    })}).then((response)=>{
                        if (response.status == 401) {
                            localStorage.removeItem("token");
                            navigate("/signin");
                        }
                        if (!response.ok) {
                            throw Error(response.statusText);
                        }
                        return response;
                    }).then(response=>response.json()).then(json_data=> {
                        props.setQueryId(json_data["query_id"]);
                        props.setQueryStatus("waiting");
                        console.log(`received response: ${JSON.stringify(json_data)}`)
                        console.log(`setting query id to: ${json_data["query_id"]}`)
                    }).catch(err=>{
                        ReactGA.event({
                            category: 'Query',
                            action: 'Query Error',
                        });
                        props.setQueryStatus("failed")
                        console.log("found error in query call:");
                        console.log(err);
                        });
    }

  useEffect(() => {
    if (multiTickerEvents.includes(selectedEvent)) {
        setPrevTicker(selectedTicker);
        setSelectedTicker("all_tickers");
    } else {
        setSelectedTicker(prevTicker);
    }
  }, [selectedEvent]);

  const steps = [
      {
        target: '#ticker-select',
        content: 'Choose your ticker (stock, ETF, cryptocurrency, etc.) here.',
      },
      {
        target: '#event-select',
        content: 'Choose your event here. Events are things like earnings calls, press releases, and other announcements. In addition, you can also analyse large price movements.',
      },
      {
        target: '#long-or-short',
        content: 'For single-ticker events, here you choose if you would like to hold the stock or short it',
      },
      {
        target: '#long-or-short',
        content: 'Multi-ticker events, such as stock recommendations, may include both long or short positions. In this case, \'long\' means that you will trade in the same direction as recommended, and \'short\' means that you will trade in the opposite direction.',
      },
  ];

  return (
  <>
    <Joyride steps={steps} run={true} />
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={2}>
          <Grid item lg={9} sm={6}>
            {/*<Typography sx={labelStyle}>
              Summary of Query
            </Typography>
            <br />*/}
            <Typography sx={bodyStyle}>
                {querySummary}
            </Typography>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <Button sx={buttonStyle} onClick={runQuery}>Go!</Button><br /><br />
            {/*<Button sx={buttonStyleHighlight}  onClick={() => {setStartDate(dayjs("2000-01-01"))}} >Max Date Range</Button><br /><br />*/}
            {/*<Button sx={buttonStyleHighlight}  onClick={() => {window.location.href = (`mailto:${contactData.default_email}`)}} >Want to analyse an event outside our database? <br /> Contact us</Button>*/}
          </Grid>
          <Grid item xs={12}>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <Typography sx={labelStyle}>
              Ticker
            </Typography>
            <Select
                value={selectedTicker}
                style={{width: '100%'}}
                onChange={(e)=>{setSelectedTicker(e.target.value)}}
                id="ticker-select"
                disabled={multiTickerEvents.includes(selectedEvent)}>
                {tickers.map((ticker, index) => {
                    return (<MenuItem value={ticker.label} disabled={typeof enabledTickers === "undefined" ? false : !enabledTickers.includes(ticker.label)}>{ticker.value}</MenuItem>)
                })}
                <MenuItem value={"all_tickers"} sx={{display: multiTickerEvents.includes(selectedEvent) ? "block" : "none"}} disabled={multiTickerEvents.includes(selectedEvent)}>(All tickers enabled)</MenuItem>
            </Select>
            </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <Typography style={labelStyle}>
              Event/Signal
            </Typography>
            <Select
                value={selectedEvent}
                style={{width: '100%'}}
                id="event-select"
                onChange={(e)=>{setSelectedEvent(e.target.value)}}>
                {Object.keys(eventNames).map((event_id, index) => {
                    return (<MenuItem value={event_id} disabled={typeof enabledEvents === "undefined" ? false : !enabledEvents.includes(event_id)}>{eventNames[event_id]}</MenuItem>)
                })}
            </Select>
          </Grid>

          <Grid item lg={3} sm={6} xs={12}>
            <Tooltip title={varDescriptions["from_date"]} style={{textAlign: "left"}}>
            <Typography sx={labelStyle}>
              Start
            </Typography>
            </Tooltip>
            <DesktopDatePicker
              inputFormat="DD-MMM-YYYY"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => <TextField style={{width: '100%'}} {...params} />}
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <Tooltip title={varDescriptions["to_date"]} style={{textAlign: "left"}}>
            <Typography style={labelStyle}>
              End
            </Typography>
            </Tooltip>
            <DesktopDatePicker
              inputFormat="DD-MMM-YYYY"
              value={endDate}
                class="ticker-select"

              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => <TextField style={{width: '100%'}} {...params} />}
            />
          </Grid>

          {(nonFundamentalEvents.includes(selectedEvent)) &&
            (<MoveQueryOptions setExtraParams={setExtraParams}
                    varDescriptions={varDescriptions}
                    defaultLongOrShort={props.defaultLongOrShort} />)
            }
          {(!nonFundamentalEvents.includes(selectedEvent)) &&
            (<FundamentalQueryOptions setExtraParams={setExtraParams}
                    varDescriptions={varDescriptions}
                    defaultLongOrShort={props.defaultLongOrShort} />)
           }
          <Grid item xs={12} mt={3}>
            <Typography sx={labelStyle}>
                Want to analyse an event outside our database? <Typography   onClick={() => {window.location.href = (`mailto:${contactData.default_email}`)}} sx={labelStyleHighlight} display="inline">Contact us</Typography>

            </Typography>
          </Grid>
        </Grid>
        </LocalizationProvider>
  </>
    );
}

QueryInput.defaultProps = {
    defaultEvent: "MOVE",
    defaultEventDesc: "% Move within a particular time",
    defaultTicker: "QQQ",
    defaultLongOrShort: true,
  }

export default QueryInput;
