
import '../App.css';

import React, {useEffect, useState} from 'react';
import {Grid, Button, Typography, Drawer, Box, IconButton, Divider} from "@mui/material";

import { useNavigate, Link } from "react-router-dom";

import {Person, KeyboardArrowUp} from '@mui/icons-material';


import {color_palette, font_palette} from '../style/StylePalette.js';

import logo from '../static/logo.svg';

function  HamburgerMenu  (props){
  const linkStyle = {
    fontFamily: font_palette["text"],
    color: color_palette["white"],
    fontSize: 18,
    textAlign: "left",
  }

  const iconStyle = {
    fontFamily: font_palette["text"],
    color: color_palette["white"],
    fontSize: 18,
    display: "flex",
    alignItems: "center",
  }

  const buttonStyle = {
    fontFamily: font_palette["text"],
    color: color_palette["white"],
    fontSize: 18,
    borderRadius: 6,
    border: "1px solid " + color_palette["primary"],
  }
  const navigate = useNavigate();

  return (<Drawer
               variant="persistent"
               anchor="top"
               open={props.drawerOpen}

               PaperProps={{
                  sx: {
                  background: "#000000",
                  color: "white",
                  height: "100%",
                  maxWidth: "100%",
                  width: {
                     xs: "100%",
                     sm: 400,
                  },
                  }
               }}

        >
           <Box ml={2} mr={2}>
          <Box  mt={2}>
             <IconButton mr={2} onClick={()=>{props.setDrawerOpen(false)}}  sx={{color: "white"}}>
                <KeyboardArrowUp
                   sx={{cursor: "pointer",
                      fontSize: 36}}
                   />

             </IconButton>
          </Box>
          <Box mt={3} mb={3}>
          <Divider sx={{backgroundColor: color_palette["white"]}} />
          </Box>
          <Box mt={3}>
          <Link onClick={()=>props.setDrawerOpen(false)}  to="/query/cramer" style={linkStyle}>Inverse Cramer</Link>
          </Box>
          <Box mt={3}>
          <Link onClick={()=>props.setDrawerOpen(false)} to="/query" style={linkStyle}>Analyse an Event</Link>
          </Box>
          <Box mt={3}>
          <Link onClick={()=>props.setDrawerOpen(false)} to="/about" style={linkStyle}>About</Link>
          </Box>
          <Box mt={3}>
          <Link onClick={()=>props.setDrawerOpen(false)} to="/eventstudies" style={linkStyle}>Event Studies</Link>
          </Box>
          <Box mt={3}>
              {props.loggedIn &&
                <Link to="/profile" style={linkStyle}>Your Profile</Link>
              }
          </Box>
          <Box mt={3} mb={3}>
          <Divider sx={{backgroundColor: color_palette["white"]}} />
          </Box>
          <Box mt={3}>
              {!props.loggedIn && (
              <Button style={buttonStyle}  onClick={()=>{props.setDrawerOpen(false);navigate(`/signin`)}}>
                Sign In/Sign Up
              </Button>
              )}
              {props.loggedIn && (
              <Button style={buttonStyle}  mr={0} onClick={()=>{localStorage.removeItem("token"); window.dispatchEvent(new Event("storage")); props.setDrawerOpen(false); props.setLoggedIn(false)}}>
                Sign Out
              </Button>
              )}
          </Box>
      </Box>
    </Drawer>);
}

export default HamburgerMenu;
