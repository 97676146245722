
import '../App.css';

import React, {useEffect, useState} from 'react';
import {ImageListItem, Card, CardHeader, CardContent, Typography, Divider} from "@mui/material";

import { useNavigate, Link } from "react-router-dom";

import {Email, Instagram, Twitter, Facebook} from '@mui/icons-material';


import {color_palette, font_palette} from '../style/StylePalette.js';

import logoSimple from '../static/logo_simple.svg';

function  BenefitCard  (props){
  return (<Card variant="outlined" sx={{borderRadius: 5, width: "100%", minHeight: 350, backgroundColor: color_palette["black"]}}>
    <CardContent>
    <div  sx={{overflow: "scroll"}}>
    <Typography ml={3} mr={3} sx={{color: color_palette["white"], fontSize: 36, textAlign: "left"}}>
        <img src={logoSimple} style={{width: 30}} /> {props.name}
    </Typography>
    </div>

    <Divider style={{color: color_palette["white"]}} />
    <br />
    <Typography ml={3} mr={3} sx={{color: color_palette["white"], textAlign: "left"}}>
    {props.children}
    </Typography>
    </CardContent>
    </Card>);
}

export default BenefitCard;
