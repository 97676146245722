
import '../App.css';

import React, {useEffect, useState} from 'react';
import {Grid, Button, Typography} from "@mui/material";

import { globalData, contactData } from '../Global.js';
import { useNavigate, Link } from "react-router-dom";

import {Email, Instagram, Twitter, Facebook} from '@mui/icons-material';


import {color_palette, font_palette} from '../style/StylePalette.js';

import logo from '../static/logo.svg';

function  Footer  (props){
  const linkStyle = {
    fontFamily: font_palette["text"],
    color: color_palette["white"],
    fontSize: 14,
    textAlign: "center",
  }

  const grayStyle = {
    fontFamily: font_palette["text"],
    color: color_palette["gray"],
    fontSize: 14,
    textAlign: "center",
  }

  const iconStyle = {
    fontFamily: font_palette["text"],
    color: color_palette["gray"],
    fontSize: 30,
    textAlign: "center",
    cursor: "pointer",
  }

  const buttonStyle = {
    fontFamily: font_palette["text"],
    color: color_palette["white"],
    fontSize: 12,
    borderRadius: 6,
    border: "1px solid " + color_palette["primary"],
  }
    const TWITTER_ACCOUNT = "none";

  return (<>
  <div style={{height: "100%"}} mb={0}>
  <Grid container style={{position: "sticky", bottom: 0, backgroundColor: color_palette["black"], display: "flex", alignItems: "center"}}>
    <Grid item xs={12} ml={20}>

    <br /><br />
    <img src={logo} style={{display: "flex", minHeight: 200*(2/3), minWidth: 200, height: "40%", width: "25%", justifyContent: "left"}} />
    </Grid>
    <Grid item xs={12}>
    <br /><br />
    </Grid>
    <Grid item xs={3} ml={5}>
        <Typography sx={grayStyle}>Copyright © 2022 anagami.ai</Typography>
    </Grid>
    <Grid item xs={2}>
        <Typography sx={grayStyle}>{/*Terms of Service*/}</Typography>
    </Grid>
    <Grid item xs={2}>
        <Typography sx={grayStyle}>{/*Privacy Policy*/}</Typography>
    </Grid>
    <Grid item xs={3} mr={5} style={{textAlign: "right"}}>
        <Email style={iconStyle}  onClick={() => {window.location.href = (`mailto:${contactData.default_email}`)}} />
        <Twitter style={iconStyle} onClick={() => {window.location.href = (`https://twitter.com/${TWITTER_ACCOUNT}`)}}  />

    </Grid>
  <Grid item xs={12} ml={25}>
    <br /><br />
  </Grid>
  </Grid>
  </div>
</>);
}

export default Footer;
