import '../App.css';

import {React, useState} from 'react';
import {Grid, Button, Typography, Link, TextField} from "@mui/material";

import {color_palette, font_palette} from '../style/StylePalette.js';

import { useNavigate } from "react-router-dom";

import {globalData} from '../Global.js';

import Signup from '../components/Signup.js';
import Signin from '../components/Signin.js';

import hero from '../static/hero.jpg';

function  ForgotPassword  (props) {
 const headerStyle = {
    fontFamily: font_palette["header"],
    color: color_palette["black"],
    fontSize: 36,
    fontWeight: "bolder",
 }
 const descStyle = {
    fontFamily: font_palette["header"],
    color: color_palette["black"],
    fontSize: 36,
 }
 const buttonStyle = {
    fontFamily: font_palette["text"],
    color: color_palette["white"],
    backgroundColor: color_palette["primary"],
    fontSize: 12,
    width: "40%",
 }
    const labelStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["gray"],
        fontSize: 16,
     }
    const linkStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["link"],
        fontSize: 16,
        cursor: "pointer",
     }

  const navigate = useNavigate();

     const resetPassword = () => {
        fetch(`${globalData.api_root}/user/reset_password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                crossDomain: true,
                body: JSON.stringify({
                        username: email,
                })}).then(response=>{
                    //navigate("/signin");
                });
        setStatusText("Thank you. If an account exists with the email, we will send you a new password")
     }
    const [statusText, setStatusText] = useState("");

    const [email, setEmail] = useState("");

  return (

      <>
      <Grid container>
      {statusText.length == 0 && (<>
      <Grid item xs={12}>
          <Grid item xs={12} mt={6}>
            <Typography style={headerStyle}>Reset Password</Typography>
            <br /><br />
            </Grid>

            <Typography sx={labelStyle}>
              Email*
            </Typography>
            <TextField value={email} sx={{maxWidth: {md: "20%", xs: "80%"}, width: "100%"}} onKeyDown={(e)=>{if (e.key==="Enter"){resetPassword()}}} onChange={(e) => setEmail(e.target.value)}>
            </TextField>
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={6} mt={6}>
        <Button  style={buttonStyle} onClick={resetPassword}>
          Reset Password
        </Button>
      </Grid>
      <Grid item xs={3} /></>)}
      {statusText.length > 0 && (
        <Grid item xs={12} mt={6}>
            <Typography>Thank you. You will receive an email with a new password, provided an account exists.</Typography>
        </Grid>
      )}

      </Grid>
      </>
  );
}

export default ForgotPassword;
