import '../App.css';

import React, { useEffect, useState } from 'react';
import {Card, Typography, Button, Divider, Grid, TextField, Select, MenuItem} from "@mui/material";
import { useNavigate } from "react-router-dom";

import {globalData} from '../Global.js';

import ReactGA from 'react-ga4';


import dayjs from 'dayjs';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {color_palette, font_palette} from '../style/StylePalette.js';
import {checkValidPassword, checkValidEmail, minimumPasswordLength, maximumPasswordLength} from '../util/UserValidation.js';

function  Signup  (props) {

    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [signupCode, setSignupCode] = useState("");
    const [password, setPassword] = useState("");

    const [signupStatus, setSignupStatus] = useState("");

    const [incorrectPassword, setIncorrectPassword] = useState(false);
    const [incorrectPasswordText, setIncorrectPasswordText] = useState("");
    const [incorrectEmail, setIncorrectEmail] = useState(false);
    const [incorrectEmailText, setIncorrectEmailText] = useState("");
    const [signupDisabled, setSignupDisabled] = useState(true);

    const labelStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["gray"],
        fontSize: 16,
     }

    const buttonStyle = {
        fontFamily: font_palette["text"],
        color: color_palette["white"],
        backgroundColor: signupDisabled ? color_palette["highlight_gray"] : color_palette["highlight"],
        fontSize: 12,
        width: "100%",
        maxWidth: "20%",
    }
    const bodyStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["black"],
        fontSize: 16,
     }

    const checkDisabled = () => {
        if (checkValidPassword(password)) {
            setSignupDisabled(false);
        } else {
            setSignupDisabled(true);
        }
    }

    const signUp = () => {
        var canSignUp = true;
        if (!checkValidPassword(password)) {
            setIncorrectPassword(true);
            if (password.length < minimumPasswordLength) {
                setIncorrectPasswordText(`Password must be at least ${minimumPasswordLength} characters`);
            } else if (password.length > maximumPasswordLength) {
                setIncorrectPasswordText(`Password must be at most ${maximumPasswordLength} characters`);
            } else {
                setIncorrectPasswordText(`Invalid password`);
            }

            canSignUp = false;
        }

        if (!checkValidEmail(email)) {
            setIncorrectEmail(true);
            setIncorrectEmailText("Invalid email");
            canSignUp = false;
        }

        if (!canSignUp) {
            setSignupDisabled(true);
            return;
        }

        ReactGA.event({
            category: 'User',
            action: 'Sign Up',
        });
        fetch(`${globalData.api_root}/user/create`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    crossDomain: true,
                    body: JSON.stringify({
                            email: email,
                            signup_code: signupCode,
                            password: password
                    })}).then(response=>{
                        if (!response.ok) {
                            throw new Error(`failed (${response.status})`)
                        }
                        return response.json();
                    }).then(json_data=> {
                        localStorage.setItem("token", json_data["access_token"]);
                        window.dispatchEvent(new Event("storage"));
                        navigate("/query");
                    }).catch(err=>{
                        ReactGA.event({
                            category: 'User',
                            action: 'Sign Up Error',
                        });
                        console.log("found error:");
                        console.log(err);
                        setSignupStatus("Sign up failed, please try again...");
                        });
    }

    useEffect(() => {
        if (!checkValidPassword(password) && password.length > 0) {
            setIncorrectPassword(true);
            if (password.length < minimumPasswordLength) {
                setIncorrectPasswordText(`Password must be at least ${minimumPasswordLength} characters`);
            } else if (password.length > maximumPasswordLength) {
                setIncorrectPasswordText(`Password must be at most ${maximumPasswordLength} characters`);
            } else {
                setIncorrectPasswordText(`Invalid password`);
            }

        } else {
            setIncorrectPassword(false);
            setIncorrectPasswordText("");
        }
        checkDisabled();
    }, [password]);

    useEffect(() => {
        if (incorrectEmail) {
            if (!checkValidPassword(password) && email.length > 0) {
                setIncorrectEmail(true);
                setIncorrectEmailText("Please enter a correct email");
            } else {
                setIncorrectEmail(false);
                setIncorrectEmailText("");
            }
            checkDisabled();
        }
    }, [email]);

    const updatePassword = (e) => {
        setPassword(e.target.value);
    }

    const updateEmail = (e) => {
        setEmail(e.target.value);
    }

  return (
      <>
      <Grid item xs={12}>
            <Typography sx={{color: "red"}}>
                {signupStatus}
            </Typography>
            <Typography sx={labelStyle} mt={2}>
              Email*
            </Typography>
            <TextField
                error={incorrectEmail}
                helperText={incorrectEmailText}
                value={email}
                sx={{maxWidth: {md: "20%", xs: "80%"}, width: "100%"}}
                onKeyDown={(e)=>{if (e.key==="Enter"){signUp()}}}
                onChange={updateEmail}>
            </TextField>

            <Typography sx={labelStyle} mt={2}>
              Signup Code (for premium beta access)
            </Typography>
            <TextField value={signupCode} sx={{maxWidth: {md: "20%", xs: "80%"}, width: "100%"}} onKeyDown={(e)=>{if (e.key==="Enter"){signUp()}}} onChange={(e) => setSignupCode(e.target.value)}>
            </TextField>

            <Typography sx={labelStyle} mt={2}>
              Password*
            </Typography>
            <TextField
                error={incorrectPassword}
                helperText={incorrectPasswordText}
                type="password"
                sx={{maxWidth: {md: "20%", xs: "80%"}, width: "100%"}}
                onKeyDown={(e)=>{if (e.key==="Enter"){signUp()}}}
                value={password}
                onChange={updatePassword}>
            </TextField>
      </Grid>
      <Grid item xs={12}>
        <Button
            disabled={signupDisabled}
            style={buttonStyle}
            onClick={signUp}>
          Sign Up
        </Button>
      </Grid>
      </>
    );
}

export default Signup;