export var color_palette = {
        "primary": "#ec7760",
        "highlight": "#ef8b78",
        "highlight_gray": "#c9a39c",
        "complement1": "#116b89",
        "complement2": "#11896b",
        "black": "#000000",
        "white": "#FFFFFF",
        "gray": "#6c757d",
        "link": "#ec7760",
  }
  
  
  export var font_palette = {
    "header": "Inter",
    "subheader": "Inter",
    "text": "Inter"
  }
  