import '../App.css';

import React from 'react';
import {Box, Typography} from "@mui/material";

import {color_palette, font_palette} from '../style/StylePalette.js';

import Signup from '../components/Signup.js';
import Signin from '../components/Signin.js';

import { useNavigate, Link } from "react-router-dom";

import hero from '../static/hero.jpg';

function  QueryFailed  (props) {
     const headerStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["black"],
        fontSize: 36,
        fontWeight: "bolder",
     }
     const descStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["black"],
        fontSize: 36,
     }
     const buttonStyle = {
        fontFamily: font_palette["text"],
        color: color_palette["white"],
        backgroundColor: color_palette["primary"],
        fontSize: 12,
        width: "40%",
     }
    const labelStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["gray"],
        fontSize: 16,
     }
    const linkStyle = {
        fontFamily: font_palette["header"],
        color: color_palette["link"],
        fontSize: 16,
     }
  return (<>
        <Box>
            <br /><br />
            <Typography sx={descStyle}>Query Failed.</Typography>
            <br />
            <Link to="/query" style={linkStyle}>Please Try Again. </Link>
        </Box>
</>);
}

export default QueryFailed;
