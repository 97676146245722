
import '../App.css';

import React, {useEffect, useState} from 'react';
import {Grid, Button, Typography, IconButton, Box} from "@mui/material";

import { useNavigate, Link } from "react-router-dom";

import {Person, Menu} from '@mui/icons-material';

import HamburgerMenu from './HamburgerMenu.js';

import {color_palette, font_palette} from '../style/StylePalette.js';

import logo from '../static/logo.svg';

function  NavBar  (props){
  const linkStyle = {
    fontFamily: font_palette["text"],
    color: color_palette["white"],
    fontSize: 14,
    textAlign: "left",
  }

  const iconStyle = {
    fontFamily: font_palette["text"],
    color: color_palette["white"],
    fontSize: 18,
    display: "flex",
    alignItems: "center",
  }

  const buttonStyle = {
    fontFamily: font_palette["text"],
    color: color_palette["white"],
    fontSize: 12,
    borderRadius: 6,
    border: "1px solid " + color_palette["primary"],
  }
  const navigate = useNavigate();

  const [loggedIn, setLoggedIn] = useState(localStorage.getItem("token") !== null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
      window.addEventListener("storage", ()=>{
        setLoggedIn(localStorage.getItem("token") !== null);
       })
  }, []);

  return (<>
  <div style={{height: "100%", backgroundColor: color_palette["black"]}}>
  <Grid container spacing={2} style={{display: "flex", alignItems: "center"}}>
      <Grid item xs={8} md={2} ml={{md: 12, xs:5}} sx={{align: {xs: "left", sm: "center"}, display: "flex"}}>
      <Button onClick={()=>navigate("/")} style={{height: 60}} ml={5}>
        <img src={logo} height="200" width="300" />
      </Button>
      </Grid>
      <Grid item xs={0} md={2} lg={0}></Grid>
      <Grid item md={1} xs={0} sx={{display: {md: "block", xs: "none"}}}>
      {/*<Link to="/query/resultWithInput/0" style={linkStyle}>Inverse Cramer</Link>*/}
      <Link to="/query/cramer" style={linkStyle}>Inverse Cramer</Link>
      </Grid>
      <Grid item md={1} xs={0} sx={{display: {md: "block", xs: "none"}}}>
      <Link to="/query" style={linkStyle}>Analyse an Event</Link>
      </Grid>
      <Grid item md={1} xs={0}  sx={{display: {md: "block", xs: "none"}}}>
      <Link to="/subscriptions" style={linkStyle}>Subscribe</Link>
      </Grid>
      <Grid item md={1} sx={0} sx={{display: {lg: "block", xs: "none"}}}>
      <Link to="/about" style={linkStyle}>About</Link>
      </Grid>
      <Grid item md={1} xs={0}  sx={{display: {md: "block", xs: "none"}}}>
      <Link to="/eventstudies" style={linkStyle}>Event Studies</Link>
      </Grid>
      <Grid item xs={0} md={1} sx={{display: {md: "block", xs: "none"}}}>
          {loggedIn &&
          <Button mr={{xs: 10, xs: 0}}  onClick={()=>navigate(`/profile`)}>
            <Person style={{color: color_palette["white"], verticalAlign: "center"}} />
          </Button>}
       </Grid>
      <Grid item md={1} xs={0} mr={0} sx={{color: color_palette["white"], display: {md: "block", xs: "none"}}}>
          {!loggedIn && (
          <Button style={buttonStyle}  onClick={()=>navigate(`/signin`)}>
            Sign In/Sign Up
          </Button>
          )}
          {loggedIn && (
          <Button style={buttonStyle}  mr={0} onClick={()=>{localStorage.removeItem("token"); window.dispatchEvent(new Event("storage")); setLoggedIn(false)}}>
            Sign Out
          </Button>
          )}
      </Grid>
      <Grid item md={0} xs={2} mr={0} sx={{color: color_palette["white"], display: {md: "none", xs: "flex"}, justifyContent: "right"}}>
        <Box mr={0} sx={{color: "white", justifyContent: "right"}}>
           <IconButton  onClick={()=>{setDrawerOpen(true)}} sx={{color: "white", justifyContent: "right"}}>
              <Menu sx={{fontSize: 36}} />
           </IconButton>
        </Box>
      </Grid>
  </Grid>
  </div>
  <HamburgerMenu drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
</>);
}

export default NavBar;
