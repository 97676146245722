
import '../App.css';

import React, {useEffect, useState} from 'react';
import {ImageListItem, Card, CardHeader, CardContent, Typography, Divider} from "@mui/material";

import { useNavigate, Link } from "react-router-dom";

import {Email, Instagram, Twitter, Facebook} from '@mui/icons-material';


import {color_palette, font_palette} from '../style/StylePalette.js';

import logoSimple from '../static/logo_simple.svg';

function  ProblemCard  (props){
  return (<Card variant="outlined" sx={{borderRadius: 5, minHeight: 350, width: "100%", border: `1px solid ${color_palette["primary"]}`}}>
    <CardContent>
    <Typography ml={3} sx={{color: color_palette["black"], fontSize: 36, textAlign: "left"}}>
       {props.title}
    </Typography>

    <Divider style={{color: color_palette["black"]}} />
    <br />
    <Typography ml={3} sx={{color: color_palette["black"], textAlign: "left"}}>
    {props.children}
    </Typography>
    </CardContent>
    </Card>);
}

export default ProblemCard;
